import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="steps"
export default class extends Controller {
  static targets = [ "item" ]

  connect() {
  }

  next() {
    // find next item and slide down the its child element with class body
    let nextItem = this.itemTargets.find((item) => item.querySelector(".body.hidden"))

    if (nextItem)
        nextItem.querySelector('.body').classList.remove('hidden')
  }
}
