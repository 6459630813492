import Rails from "@rails/ujs";
import { Controller } from "@hotwired/stimulus"
import Sortable from 'sortablejs/modular/sortable.core.esm.js';

// Connects to data-controller="property-images"
export default class extends Controller {
  static outlets = [ "loader" ]

  static targets = [
      "addPhotosInput",
      "addPhotosSubmit",
      "addPhotosButton",
      "form",
      "list",
  ]

  connect() {
    this.sortable = Sortable.create(this.listTarget, {
        onEnd: () => { this.updateImageListOrder() }
    })
  }

  openFileSelect(event) {
    event.preventDefault()
    this.addPhotosInputTarget.click()
  }

  submit() {
    this.addPhotosSubmitTarget.click()
  }

  updateImageListOrder() {
    Rails.fire(this.formTarget, 'submit');
  }
}
