import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="volume"
export default class extends Controller {
  static outlets = [
      "user-calendar-price-percentage",
  ]

  static values = {
    mode: String,
  }

  static targets = [
      "percentageInput",
  ]

  connect() {
  }

  increase(event) {
    event.preventDefault()
    let percentage = parseInt(this.percentageInputTarget.value || 0)
    if(this.modeValue === "under_zero") {
      if(percentage < 0)
        this.percentageInputTarget.value = percentage + 1
    } else
      this.percentageInputTarget.value = percentage + 1

    this.calculate_new_price()
  }

  decrease(event) {
    event.preventDefault()
    let percentage = parseInt(this.percentageInputTarget.value || 0)
    if(this.modeValue === "under_zero" || this.modeValue === "under_and_above_zero") {
      this.percentageInputTarget.value = percentage - 1
    } else {
      if(percentage > 0)
        this.percentageInputTarget.value = percentage - 1
    }

    this.calculate_new_price()
  }

    calculate_new_price(event) {
      if(this.hasUserCalendarPricePercentageOutlet)
        this.userCalendarPricePercentageOutlet.calculate_new_price()
    }
}
