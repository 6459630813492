import { Controller } from "@hotwired/stimulus"
import { userCalendarMixins } from "./mixins/user/calendar";

// Connects to data-controller="calendar"
export default class extends Controller {
  static outlets = [
      "loader"
  ]

  static values = {
    identifier: String,
    checkin: String,
    checkout: String,
  }

  static targets = [
    "calendar",
    "sidebar",
    "rateList",
    "icalInputUrl",
  ]

  connect() {
    // init commons
    axios.defaults.headers.common['Accept']       = "text/vnd.turbo-stream.html"
    axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    this.calendar_min                             = moment();
    this.calendar_max                             = moment().add(24, 'M');

    // init mixins
    userCalendarMixins(this);
    this.calendarOnRangeSelectUrl                 = document.querySelector("meta[name='calendar-on-range-select']").getAttribute("content")
    this.calendarGetRatesUrl                      = document.querySelector("meta[name='rates-for-calendar']").getAttribute("content")
    this.calendarAddEmptyRuleUrl                  = document.querySelector("meta[name='calendar-add-empty-rule']").getAttribute("content")
    this.calendarAddRuleUrl                       = document.querySelector("meta[name='calendar-add-rule']").getAttribute("content")
    this.calendarGrossLabelsUrl                   = document.querySelector("meta[name='price-labels-for-calendar']").getAttribute("content")
    this.newRule                                  = document.querySelector("#new-rule")
    this.calendarModal                            = document.querySelector("#calendar-modal")

    this.init_calendar();
    this.onFormSubmit();
    this.resetValidationsOnForms();
  }

  init_calendar() {
    const data = Promise.all([this.get_rates(), this.get_gross_labels()])

    data.then((response) => {
      this.calendar = mobiscroll.datepicker(this.calendarTarget, {
        select: 'range',
        display: 'inline',
        calendarType: 'month',
        calendarSize: 1,
        min: this.calendar_min,
        max: this.calendar_max,
        colors: this.rates,
        labels: this.gross_labels,
        showRangeLabels: false,
        inRangeInvalid: false,
        rangeEndInvalid: true,
        onPageLoaded: (event, inst) => {
          this.convert_text_to_labels()
        },
        onChange: (event, inst) => {
          if (event.value[0] && !event.value[1]) return

          this.onRangeSelect(this.convert_date(event.value[0]), this.convert_date(event.value[1]))
          this.create_minimum_stay_labels()
        }
      })
    })
  }

  onRangeSelect(from, to) {
    axios.post(this.calendarOnRangeSelectUrl, {from: from, to: to}).then((response)=> {
        Turbo.renderStreamMessage(response.data)
    })
  }

  reset_calendar() {
    const data = Promise.all([this.get_rates(), this.get_gross_labels()])

    data.then((response) => {
      this.empty_labels()
      this.calendar.setOptions({ colors: this.rates, labels: this.gross_labels })
      this.convert_text_to_labels()
      this.reset_calendar_selection()
    })
  }

  empty_labels(){
    let labels                = document.querySelectorAll(".mbsc-calendar-labels")
    let minimum_stay_labels   = document.querySelectorAll(".cell-minimum-stay-label")

    // empty labels if they exist
    if(labels) labels.forEach((element) => {
        element.innerHTML = ""
    })

    // delete minimum stay labels if they exist
    if(minimum_stay_labels) minimum_stay_labels.forEach((element) => {
        element.remove()
    })
  }

  reset_calendar_selection(){
    this.calendar.setVal()
  }

  onFormSubmit(event) {
    document.addEventListener('turbo:submit-end', (event) => {
      this.reset_calendar()
      this.resetValidationsOnForms()
    })
  }

  convert_date(date) {
    return moment(date).format("YYYY-MM-DD")
  }

  copy_icap_url_to_clipboard(event) {
    navigator.clipboard.writeText(this.icalInputUrlTarget.value)
  }
}
