import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="header"
export default class extends Controller {
  static outlets = [
    "sign-in",
    "sign-up"
  ]

  connect() {}

  open_sign_in(){
    this.signInOutlet.open()
  }

  open_sign_up(){
    this.signUpOutlet.open()
  }
}
