import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="tabs"
export default class extends Controller {
  connect() {
  }

  toggle_item(event) {
    let item    = event.currentTarget.closest('.item')
    let content = item.querySelector('.content')
    let arrow   = item.querySelector('.arrow')

    if (arrow.classList.contains('fa-chevron-down')) {
        arrow.classList.remove('fa-chevron-down')
        arrow.classList.add('fa-chevron-right')
        content.classList.add('hidden')
    } else {
        arrow.classList.remove('fa-chevron-right')
        arrow.classList.add('fa-chevron-down')
        content.classList.remove('hidden')
    }


  }

  cancel(event) {
    event.preventDefault()

    let item    = event.currentTarget.closest('.item')
    let content = item.querySelector('.content')
    let arrow   = item.querySelector('.arrow')

    // on arrow field toggle fa-chevron-down and fa-chevron-right
    if (arrow.classList.contains('fa-chevron-down')) {
        arrow.classList.remove('fa-chevron-down')
        arrow.classList.add('fa-chevron-right')
    }

    content.classList.add('hidden')
  }
}
