import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="checkout-book-success"
export default class extends Controller {
  static targets = [
    "wrapper",
    "inner"
  ]

  connect() {
    this.open(4)
  }

  open(timer) {
    this.wrapperTarget.classList.remove("hidden")
    this.innerTarget.classList.add("animate__fadeInDown")

    setTimeout(function() {
      document.querySelector(".animated-check").classList.add("drawn")
    }, 500);

    // if timer is set remove pop up and redirect to root_path after timer seconds
    if (timer) {
      setTimeout(() => {
        this.element.remove()
        window.location.href = "/"
      }, timer * 1000)
    }
  }

  close() {
    this.innerTarget.classList.remove("animate__fadeInDown")
    this.wrapperTarget.classList.add("hidden")
  }
}
