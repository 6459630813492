require('neo-async');
require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

// stimulus && turbo
import 'controllers'
import "@hotwired/turbo-rails"

// css
import '../stylesheets/application.scss'

import 'script-loader!jquery-validation/dist/jquery.validate'
import 'script-loader!../src/profile'
import 'script-loader!../src/vendor/tipso.min'
import 'script-loader!../src/vendor/mobiscroll/js/mobiscroll.javascript';
import 'script-loader!../../../node_modules/@glidejs/glide/dist/glide.min.js';

$(document).on("turbo:load", function(){
    document.addEventListener("turbo:frame-load", () => {
        init_tipso();
    })

    init_tipso()

    $.extend($.validator.messages, {
        required: "Required",
    })



    $.validator.addMethod("checkbox", function(value, element) {
        return $(element).is(":checked");
    }, "Please check this box to continue.");


    if($('.simple_form:not(.dont-validate)').length > 0) {
        $('.simple_form:not(.dont-validate)').each(function () {
            $(this).validate({
                ignore: [],
                invalidHandler: function (event, validator) {
                    $.each(validator.errorList, function (index, error) {
                        scrollTo(error.element)
                        return false;
                    });
                },
            })
        })
    }

    function init_tipso()
    {
        $('.hint').tipso({
            background: '#000',
            color: '#fff',
            position: 'top',
        });
    }
})
