import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="notify"
export default class extends Controller {
  connect() {
    this.set_name_index()
  }

  set_name_index(event) {
    // count bedrooms and get the last item
    let bedrooms = document.querySelectorAll('.bedroom:not(.hidden)')
    let last_bedroom = bedrooms[bedrooms.length - 1]

    if (bedrooms && last_bedroom) {
      let last_bedroom_title_field  = last_bedroom.querySelector('.item-title')
      let bedroom_inputs            = last_bedroom.querySelectorAll('input[name*="[bedroom_items_attributes]"]')

      let bedroom_title = last_bedroom_title_field.textContent
      let new_bedroom_number = bedrooms.length

      // the format of bedroom title is bedroom 1, bedroom 2, etc
      // so we need to get the number from the title and add bedrooms.length to it
      last_bedroom_title_field.textContent = bedroom_title.replace(/\d+/, new_bedroom_number)

      // loop through inputs and in the name change only the first [] after [bedroom_items_attributes] of the string to bedrooms size
      bedroom_inputs.forEach(function (input) {
        input.name = input.name.replace(/\[[0-9]+\]/, `[${bedrooms.length}]`)
      })
    }

    let other_rooms = document.querySelectorAll('.other-room:not(.hidden)')
    let last_other_room                 = other_rooms[other_rooms.length - 1]

    if (other_rooms && last_other_room) {
      let last_other_room_title_field = last_other_room.querySelector('.item-title')
      let other_inputs                = last_other_room.querySelectorAll('input[name*="[other_room_items_attributes]"]')

      let other_room_title              = last_other_room_title_field.textContent
      let new_other_room_number= other_rooms.length

      // the format of other room title is other room 1, other room 2, etc
      // so we need to get the number from the title and add other_rooms.length to it
      last_other_room_title_field.textContent = other_room_title.replace(/\d+/, new_other_room_number)

      other_inputs.forEach(function (input) {
        input.name = input.name.replace(/\[[0-9]+\]/, `[${other_rooms.length}]`)
      })
    }
  }
}
