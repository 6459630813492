import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="popup"
export default class extends Controller {
  static targets = [
    "wrapper",
    "form",
    "submitButton",
    "background",
    "close",
  ]

  connect() {
  }

  open(event) {
    this.wrapperTarget.classList.remove("hidden")
  }

  close(event) {
    this.wrapperTarget.classList.add("hidden")
  }

  handle_form_submit_button() {
    // if form exists on submit disable the submit button
    // re enable the submit button after turbo stream is complete
    // this is to prevent multiple form submissions
    if(this.hasFormTarget){
      this.formTarget.addEventListener("turbo:submit-start", (event) => {
        this.disable_submit_button()
      })
      this.formTarget.addEventListener("turbo:submit-end", (event) => {
        this.enable_submit_button()
      })
    }
  }

  disable_submit_button(){
    if(this.hasFormTarget)
      this.submitButtonTarget.disabled = true
  }

  enable_submit_button(){
    if(this.hasFormTarget)
      this.submitButtonTarget.disabled = false
  }
}
