import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="request-to-be-host"
export default class extends Controller {
  static outlets = ["sign-in", "sign-up"]

  static targets = [
    "wrapper",
    "background",
    "close",
  ]

  connect() {}

  open(){
    if(this.hasWrapperTarget)
      this.wrapperTarget.classList.remove("hidden")
  }

  close(event) {
    if(this.hasWrapperTarget) {
      this.wrapperTarget.classList.add("hidden")
    }
  }

  open_sign_in(){
    this.close()
    this.signInOutlet.open()
  }

  open_create_account(){
    this.close()
    this.signUpOutlet.open()
  }
}
