import { Controller } from "@hotwired/stimulus"
import { datepickerMixins } from "./mixins/datepicker";
import moment from "moment";

// Connects to data-controller="property-inline-datepicker"
export default class extends Controller {
  static outlets = ["property-datepicker", "property"]

  static values = {
    identifier: String,
    checkin: String,
    checkout: String,
    checkInSelectTitleMessage: String,
    checkInSelectSubHeaderMessage: String,
    checkInSelectTitleChangeMessage: String,
    checkInSelectSubHeaderChangeMessage: String,
    region: String,
    availabilityWindow: Number,
  }

  static targets = [
      "inlineDatepicker",
      "inlineSummaryDatesTitle",
      "inlineSummaryDates"
  ]

  connect() {
    this.min_datepicker_range                       = moment();
    this.max_datepicker_range                       = moment().add(12, 'M');

    this.datepickerInvalidDatesForCheckoutColors    = document.querySelector("meta[name='property-invalid-dates-for-checkout-colors']").getAttribute("content")
    this.datepickerInvalidDatesUrl                  = document.querySelector("meta[name='get-invalid-dates']").getAttribute("content")
    this.datepickerInvalidDateColorsUrl             = document.querySelector("meta[name='get-invalid-dates-colors']").getAttribute("content")
    this.datepickerAdvancedNoticeUrl                = document.querySelector("meta[name='get-property-advanced-notice-days']").getAttribute("content")
    this.datepickerPropertyPriceLabelsRatesUrl      = document.querySelector("meta[name='get-property-price-labels']").getAttribute("content")
    this.calendarGetCheckoutInvalidDatesUrl         = document.querySelector("meta[name='get-property-invalid-dates-for-checkout']").getAttribute("content")
    this.checkinField                               = document.querySelector("#property_checkin")
    this.checkoutField                              = document.querySelector("#property_checkout")

    // Init mixins
    datepickerMixins(this);

    // initializations
    this.init();
    this.fromPropertyDatepicker = false
  }

  init() {
    if (!this.hasInlineDatepickerTarget) return

    const data = Promise.all([
//        this.get_price_labels(),
        this.get_invalid_dates(),
//        this.get_invalid_date_colors(),
//        this.get_checkout_invalid_dates(),
//        this.get_advanced_notice_dates(),
//        this.get_checkout_invalid_date_colors()
    ])

    data.then((response) => {
      this.inline_datepicker = mobiscroll.datepicker(this.inlineDatepickerTarget, {
        themeVariant: 'light',
        theme: "material",
        controls: ['calendar'],
        select: 'range',
        display: 'inline',
        calendarType: 'month',
        dateFormat: 'YYYY-MM-DD',
        returnFormat: 'moment',
        pages: 2,
        min: this.min_datepicker_range,
        showRangeLabels: true,
        inRangeInvalid: false,
        rangeEndInvalid: true,
        invalid: this.invalid_dates,
//        labels: this.price_labels,
//        colors: this.invalid_dates_colors,
        renderDay: (args) => {
            let date                = moment(args.date).format('YYYY-MM-DD')
            let air_cell_div        = `<div class="air-cell date_${date}">` + args.date.getDate() + `</div>`

            let complete_html       = air_cell_div

            let invalid_date        = this.invalid_dates && this.invalid_dates.find((item) => "date" in item && moment(item["date"]).format('YYYY-MM-DD') == date)
            let is_checkout_only    = invalid_date && "cellCssClass" in invalid_date && invalid_date["cellCssClass"].includes("checkout-only")

            if(invalid_date && is_checkout_only) {

                air_cell_div        = `<div class="air-cell checkout-only date_${date}">` + args.date.getDate() + `</div>`
                let tip_cloud_div   = "<div class='tip-cloud datepicker-popup'>Checkout only</div>"

                complete_html       = air_cell_div + tip_cloud_div
            }

            let minimum_stay_div    = "<div class='minimum_stay-cloud datepicker-popup'>Minimum 7 days</div>"
            complete_html           = complete_html + minimum_stay_div

            return complete_html
        },
        onCellHoverIn: (event, inst) => {
            const checkin           = inst.getVal()[0]
            const checkout          = inst.getVal()[1]
            let is_checkin          = checkin && moment(event.date).format('YYYY-MM-DD') == moment(checkin).format('YYYY-MM-DD')
            let tip_cloud           = event.target.querySelector(".tip-cloud")
            let minimum_stay_cloud  = event.target.querySelector(".minimum_stay-cloud")

            if(!checkin && is_checkin && tip_cloud) tip_cloud.classList.add("active")
//            if(is_checkin && !checkout && minimum_stay_cloud) minimum_stay_cloud.classList.add("active")

        },
        onCellHoverOut: (event, inst) => {
           let tip_cloud            = event.target.querySelector(".tip-cloud")
           let minimum_stay_cloud   = event.target.querySelector(".minimum_stay-cloud")

           if(tip_cloud && tip_cloud.classList.contains("active")) tip_cloud.classList.remove("active")
//           if(minimum_stay_cloud && minimum_stay_cloud.classList.contains("active")) tip_cloud.classList.remove("active")
        },
        onTempChange: (event, inst) => {
            const checkin           = event.value[1]
            const checkout          = event.value[0]
            const cell_date         = moment(event.target.querySelector(".air-cell").classList[1].split("_")[1]).format('YYYY-MM-DD')
            let is_checkin          = checkin && cell_date == moment(checkin).format('YYYY-MM-DD')
            let minimum_stay_cloud  = event.target.querySelector(".minimum_stay-cloud")

            if(is_checkin && !checkout && minimum_stay_cloud) minimum_stay_cloud.classList.add("active")
        },


//        onCellHoverIn: function (event, inst) {
//            let parent      = event.target.closest(".mbsc-calendar-cell")
//            let date        = parent.querySelector(".air-cell").classList[1].split("_")[1]
//            let tip_cloud   = parent.querySelector(".tip-cloud")
//
//            if (parent) {
//                let min_stay_day = Array.from(parent.classList).find((c) => c.startsWith("min-stay-day"));
//
//                if (parent.classList.contains("mbsc-range-day-start")){
//                    // if parent has class mbsc-range-day-start we add "checkin day" to the tip cloud div
//                    tip_cloud.textContent = "checkin day"
//                } else if(parent.classList.contains("mbsc-range-day-end")){
//                    // if parent has class mbsc-range-day-start we add "checkout day" to the tip cloud div
//                    tip_cloud.textContent = "checkout day"
//                } else if (min_stay_day) {
//                    // if parent element has class min-stay-day we add the minimum stay days to the tip cloud div
//                    let min_stay_day_number = min_stay_day.split("-")[3]
//                    tip_cloud.textContent = min_stay_day_number + " minimum nights"
//                } else if (parent.classList.contains("checkout-only")) {
//                    // if parent element has class checkout-only we add the text "checkout only" to the tip cloud div
//                    tip_cloud.textContent = "checkout only"
//                } else {
//                    // return empty tip cloud div
//                    tip_cloud.textContent = ""
//                }
//            }
//
//            let checkin_selected = document.querySelector(".mbsc-range-day-start.mbsc-range-day-end")
//
//            if (checkin_selected) {
//                // from checkin_selected find the class starting with "date-" and convert it to a date
//                let checkin_date_class = checkin_selected.querySelector(".air-cell").classList[1]
//                let checkin_date       = checkin_date_class.split("_")[1]
//                let minRange           = parseInt(this.date_minimum_stays[checkin_date])
//
//                // find the last minimum stay date
//                let last_minimum_stay_date = moment(checkin_date).add(minRange, 'days').format('YYYY-MM-DD')
//
//                // between checkin and last_minimum_stay_date find tip cloud div and add the text "7 minimum nights"
//                if (moment(date).isBetween(checkin_date, last_minimum_stay_date)) {
//                    tip_cloud.textContent = minRange + " minimum nights"
//                }
//            }
//
//            // if tip cloud text is not empty we display it
//            if (tip_cloud && tip_cloud.textContent != "") tip_cloud.classList.add("active")
//        },
//        onCellHoverOut: function (event, inst) {
//            // remove active class from tip cloud
//            let tip_cloud = event.target.querySelector(".tip-cloud")
//            if (tip_cloud) tip_cloud.classList.remove("active")
//        },
//        onTempChange: (event, inst) => {
//            const checkin   = moment(event.value[0])
//            const checkout  = moment(event.value[1])
//
//            if(checkout.isAfter(checkin)){
//              this.set_summary_messages(inst)
//
//              this.propertyDatepickerOutlet.checkinTarget.value   = checkin.format('YYYY-MM-DD')
//              this.propertyDatepickerOutlet.checkoutTarget.value  = checkout.format('YYYY-MM-DD')
//              this.propertyDatepickerOutlet.datepicker.setVal([checkin.format('YYYY-MM-DD'), checkout.format('YYYY-MM-DD')])
//              this.propertyDatepickerOutlet.this.get_booking_details()
//            }
//        },
    })
  })
  }

  set_options_if_only_checkin_is_selected(inst){

      // Set the options for the datepicker if only checkin is selected
      inst.setOptions({
        minRange: parseInt(this.date_minimum_stays[this.checkin]),
        maxRange: this.maximum_stay,
        invalid:  this.checkout_invalid_dates,
      })

  }

  set_options_if_both_checkin_and_checkout_are_selected(inst){

    if(this.hasPropertyDatepickerOutlet && !this.fromPropertyDatepicker) {
        this.propertyDatepickerOutlet.fromInlineDatepicker = true

        if (this.checkin != this.checkout)
            this.propertyDatepickerOutlet.datepicker.setVal([this.checkin, this.checkout]);
        else
            this.propertyDatepickerOutlet.datepicker.setVal();
    }

    inst.setOptions({ invalid: this.invalid_dates })
  }

  set_value_for_datepicker_outlet(inst) {
    if(this.hasPropertyDatepickerOutlet) {
      this.propertyDatepickerOutlet.datepicker.setVal([inst.getVal()[0], inst.getVal()[1]]);

      this.propertyDatepickerOutlet.checkinTarget.value = moment(inst.getVal()[0]).format('YYYY-MM-DD');
      this.propertyDatepickerOutlet.checkoutTarget.value = moment(inst.getVal()[1]).format('YYYY-MM-DD');

      this.propertyDatepickerOutlet.get_booking_details()
    }
  }

  set_summary_messages(inst) {
        // Change summary messages based on datepicker values
        if(inst.getVal()[0] && !inst.getVal()[1]){
          this.inlineSummaryDatesTitleTarget.textContent    = this.checkInSelectTitleChangeMessageValue
          this.inlineSummaryDatesTarget.textContent         = this.checkInSelectSubHeaderChangeMessageValue
          this.inlineSummaryDatesTarget.textContent         = "Minimum stay: " + (this.minimum_stay_days) + " nights"

        } else if(inst.getVal()[0] && inst.getVal()[1]) {

          const nights = moment(inst.getVal()[1]).diff(moment(inst.getVal()[0]), 'days')
          this.inlineSummaryDatesTitleTarget.textContent    = nights + " nights in " + this.regionValue

          const checkin_format                              = (moment(inst.getVal()[0]).format('MMM DD, YYYY'))
          const checkout_format                             = (moment(inst.getVal()[1]).format('MMM DD, YYYY'))

          this.inlineSummaryDatesTarget.textContent         = checkin_format + " - " + checkout_format

        } else {
          this.inlineSummaryDatesTitleTarget.textContent    = this.checkInSelectTitleMessageValue
          this.inlineSummaryDatesTarget.textContent         = this.checkInSelectSubHeaderMessageValue
        }
  }

  clear_datepickers() {
    // Reset the values of the datepicker and inline datepicker
    if(this.hasPropertyDatepickerOutlet) {
      this.propertyDatepickerOutlet.datepicker.setVal();
      this.propertyDatepickerOutlet.datepickerTarget.value = "";
      this.propertyDatepickerOutlet.checkinTarget.value = "";
      this.propertyDatepickerOutlet.checkoutTarget.value = "";
      this.propertyDatepickerOutlet.get_booking_details()
    }

    this.inline_datepicker.setVal();

    // Reset the summary messages
    this.inlineSummaryDatesTitleTarget.textContent  = this.checkInSelectTitleMessageValue;
    this.inlineSummaryDatesTarget.textContent       = this.checkInSelectSubHeaderMessageValue;
  }


}
