import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="property-images-error"
export default class extends Controller {
  static outlets = ["common"]

  connect() {
    this.commonOutlet.scroll_to(this.element)
  }

  scroll_to(element){
    this.commonOutlet.scroll_to(element)
  }
}
