import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="about-us"
export default class extends Controller {
  static targets = ["timelineItemHeaders","timelineItemBodies"]

  connect() {
  }

  showTab(event) {
    this.timelineItemHeadersTargets.forEach((header) => {
        header.classList.remove('bg-black')
        header.classList.remove('text-white')
    })
    this.timelineItemBodiesTargets.forEach((body) => { body.classList.add('hidden') })

    event.currentTarget.classList.add('bg-black')
    event.currentTarget.classList.add('text-white')
    let head = event.currentTarget.getAttribute('head')

    document.querySelector(".timeline-item-body[body=" + head + "]").classList.remove('hidden')
  }
}
