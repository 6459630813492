import { Controller } from "@hotwired/stimulus"
import moment from 'moment'

// Connects to data-controller="submit-enquiry"
export default class extends Controller {
  static targets = ["dateItem", "dateWrapper", "advancedSearch"]

  connect() {
    this.init_datepicker()
  }

  open() {
    this.target.closest('.popup-wrapper').classList.remove('hidden')
  }

  close() {
    this.target.closest('.popup-wrapper').classList.add('hidden')
  }

  add_date_item(event) {
    // count the number of date items so to use the count as the index
    // increase the index by 1
    // clone the first date item and add the new index to every input name in the cloned item where it has [0]
    // clear all the input values
    // append the cloned item to the date wrapper

    let new_uuid    = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
    let index       = this.dateItemTargets.length
    let clonedItem  = this.dateItemTargets[0].cloneNode(true)

    index++

    clonedItem.innerHTML = clonedItem.innerHTML.replace(/\[0\]/g, `[${index}]`)
    clonedItem.querySelectorAll('input').forEach((input) => {
        input.value = ''
    })
    clonedItem.querySelector('input').closest("div").setAttribute("id", new_uuid)
    clonedItem.querySelector('.remove-row').classList.remove('hidden')
    this.dateWrapperTarget.appendChild(clonedItem)

    this.init_datepicker()
  }

  remove_date_item(event) {
    event.currentTarget.closest('.date-item').remove()
  }

  open_advance_search() {
    this.advancedSearchTarget.classList.remove('hidden')
  }

  toggle_advance_search() {
    if(this.advancedSearchTarget.classList.contains('hidden'))
      this.open_advance_search()
    else
      this.close_advance_search()
  }

  close_advance_search() {
    this.advancedSearchTarget.classList.add('hidden')
  }

  init_datepicker(){
    const min= moment();
    const max= moment().add(15, 'M');
    const sthis = this
    const datepicker = mobiscroll.datepicker(".submit-enquiry-mobiscroll", {
      themeVariant: 'light',
      theme: "material",
      controls: ['calendar'],
      select: 'range',
      display: 'anchored',
      dateFormat: 'DDD DD MMM, YYYY',
      rangeStartLabel: 'Check in',
      rangeEndLabel: 'Check out',
      min: min,
      max: max,
      responsive: {
        xsmall: {
          display: 'center',
          pages: 2,
          touchUi: true
        },
        medium: {
          display: 'center',
          pages: 2,
          touchUi: true
        },
        large: {
          display: 'anchored',
          pages: 2
        },
      },
      onClose: function (event, inst) {
        const check_in_format    = (moment(inst.getVal()[0]).format('YYYY/MM/DD'))
        const check_out_format   = (moment(inst.getVal()[1]).format('YYYY/MM/DD'))
        const element                   = this.element

        // get currents element check in and check out input
        const check_in_input            = element.closest('.date-item').querySelector('.check-in')
        const check_out_input           = element.closest('.date-item').querySelector('.check-out')

        // if check in and check out input is present
        // set the check in and check out input value
        if (check_in_input && check_out_input) {
          check_in_input.value  = check_in_format
          check_out_input.value = check_out_format
        }
      }
    });
  }
}
