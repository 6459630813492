import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="property-map"
export default class extends Controller {
  static targets = [
    "marketLat",
    "marketLng",
    "region",
    "address",
    "street",
    "streetNumber",
    "postCode",
    "mapPlaceholder",
    "mapField",
    "mapSearchPlaceholder"
  ]

  connect() {
    this.accessToken = 'pk.eyJ1IjoicGF4b3NyZXRyZWF0cyIsImEiOiJjbDcwbW9vb3kwZTR4M29zYmJoMmlqcmJoIn0.tKMf62W9VJTdcLspkoMrTg';

    // if market lat and lng are presen but dont have value then init map
    if(this.hasMarketLngTarget && this.hasMarketLatTarget && !this.marketLngTarget.value && !this.marketLatTarget.value)
      this.init_map()
  }

  search_map_by_text(text){
    let result = []

    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      countries: 'gr' // Limit results to the United States
    });

    geocoder.forwardGeocode({
      query: text,
      limit: 1
    }).then(function(results) {
      result = results[0].center
    });

    return result
  }

  search_region(event){
    const coordinates = this.search_map_by_text(event.target.options[event.target.selectedIndex].text)

    this.searchMap.flyTo({
      center: coordinates,
      zoom: 11.7
    });
  }

  display_map(event){
    // add on event target hidden class and init map
    if(this.hasMapPlaceholderTarget)
      event.currentTarget.classList.add('hidden')
      this.mapFieldTarget.classList.remove('hidden')

    this.init_map()
  }

  init_map(){
    const sthis = this
    mapboxgl.accessToken = this.accessToken

    const greek_coordinates = [23.673527719697205, 37.955034427633095]
    const country_level_zoom = 5.2
    const default_level_zoom = 11.7
    let coordinates= greek_coordinates;
    let zoom = country_level_zoom;

    // if marker_lat and marker_lng are present, and has value use there coordinates
    // else search mapbox using destination value if region dont have value
    // else search mapbox using region value if region has value
    // else use greek coordinates
    if(this.hasMarketLngTarget && this.hasMarketLatTarget && this.marketLngTarget.value && this.marketLatTarget.value) {
      coordinates = [this.marketLngTarget.value, this.marketLatTarget.value]
      zoom        = default_level_zoom
    } else {
      coordinates = greek_coordinates
      zoom        = country_level_zoom
    }

    const map = new mapboxgl.Map({
      container: 'user-property-detail-map', // container ID
      style: 'mapbox://styles/mapbox/streets-v11', // style URL
      center: coordinates, // starting position [lng, lat]
      zoom: zoom, // starting zoom
      projection: 'globe', // display the map as a 3D globe
    });

    // options
    map.addControl(new mapboxgl.NavigationControl(), 'top-right').scrollZoom.disable();

    // create center marker
    // if marker_lat and marker_lng are present, and has value use there coordinates
    // else use the center of the map
    let center_marker_coordinates = coordinates

    if(this.hasMarketLngTarget && this.hasMarketLatTarget && this.marketLngTarget.value && this.marketLatTarget.value)
      center_marker_coordinates = [this.marketLngTarget.value, this.marketLatTarget.value]
    else
      center_marker_coordinates = [0, 0]

    const center_marker = new mapboxgl.Marker().setLngLat(center_marker_coordinates).addTo(map);

    map.on("move", function(){
      center_marker.setLngLat(map.getCenter())
    })

    map.on('dragend', function() {
      // after dragend add the coordinates of the marker to marketLat and marketLng
      sthis.marketLatTarget.value = map.getCenter().lat
      sthis.marketLngTarget.value = map.getCenter().lng

    });

    this.searchMap = map
    this.attach_map_search()
  }

  attach_map_search() {
    const sthis = this

    // if mapSearchPlaceholder exists show it
    // and attach a map search bar to #map-search-placeholder inside it
    if(this.hasMapSearchPlaceholderTarget) {
      this.mapSearchPlaceholderTarget.classList.remove('hidden')

      const mapSearch = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
        countries: 'gr' // Limit results to the United States
      });

      mapSearch.addTo('#map-search-placeholder');

      // on search bar result move marker to the new coordinates
      mapSearch.on('result', function (result) {
        const coordinates = result.result.center
        sthis.searchMap.flyTo({
          center: coordinates,
          zoom: 11.7
        });

        sthis.marketLatTarget.value    = coordinates[1]
        sthis.marketLngTarget.value    = coordinates[0]
        sthis.addressTarget.value      = result.result.place_name
      })
    }
  }

  enable_map(){
    this.init_map()
  }

  toggle_satellite(event){
    event.preventDefault();

    if (this.searchMap.getStyle().name === 'Mapbox Streets')
      this.searchMap.setStyle('mapbox://styles/mapbox/satellite-v9');
    else
      this.searchMap.setStyle('mapbox://styles/mapbox/streets-v11');
  }
}
