import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="quantity"
export default class extends Controller {
  static targets = ["quantityInput", "guestsInput"]

  connect() {}

  increase() {
    this.quantityInputTarget.value = parseInt(this.quantityInputTarget.value) + 1

    if(this.hasGuestsInputTarget)       this.set_guests()
  }

  decrease() {
    if (this.quantityInputTarget.value > 0) {
      this.quantityInputTarget.value = parseInt(this.quantityInputTarget.value) - 1

      if(this.hasGuestsInputTarget)     this.set_guests()
    }
  }

  set_guests() {
    this.guestsInputTarget.value = this.quantityInputTarget.value
  }
}
