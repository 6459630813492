import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="user-calendar-price-percentage"
export default class extends Controller {
  static targets = [
      "newPrice",
      "newPriceWrapper",
      "percentageInput",
  ]

  static values = {
    price: Number,
  }

  connect() {}

  calculate_new_price(event) {
    // we hide the new price section
    // we calculate the new price based on the percentage which can be positive or negative
    // we show the new price section
    this.hide_new_price()

    let percentage = parseInt(this.percentageInputTarget.value || 0)
    let new_price = this.priceValue + (this.priceValue * percentage / 100)
    this.newPriceTarget.innerText = new_price.toFixed(2)

    this.show_new_price()
  }

  show_new_price(event) {
    this.newPriceWrapperTarget.classList.remove("hidden")
  }

  hide_new_price(event) {
    this.newPriceWrapperTarget.classList.add("hidden")
  }
}
