import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="outer-request-to-be-host"
export default class extends Controller {
  static outlets = ["request-to-be-host"]

  connect() {
  }

  open_request_to_be_host() {
    this.requestToBeHostOutlet.open()
  }
}
