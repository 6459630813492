import { Controller } from "@hotwired/stimulus"
import { post } from '@rails/request.js'
import moment from 'moment'

// Connects to data-controller="live-search"
export default class extends Controller {
  static outlets = ["loader"]

  static targets = [
    "propertyList",
    "amenities",
    "region",
    "regionDropdown",
    "form",
    "checkin",
    "checkout",
    "guests",
    "placeholderGuests",
    "services",
    "bedrooms",
    "bathrooms",
    "propertyType",
    "priceFrom",
    "priceTo",
    "instantBook",
    "luxuryBook",
    "mobileFiltersButton",
    "mobileRegion",
    "flexibleBook",
    "submitButton",
    "clearSearchButton",
    "sortOrder",
    "propertiesTop",
    "propertyTypesButton",
    "bedroomsButton",
    "bathroomsButton",
    "amenitiesButton",
    "serviceButton",
    "sortByButton",
    "priceButton"
  ]

  connect() {
    this.propertyListLoader                       = document.querySelector("#property-list-loader")
    this.satelliteButton                          = document.querySelector("#satellite-toggle")

    // init commons
    this.min = moment();
    this.max = moment().add(15, 'M');
    this.init_datepicker()
    this.init_map()

    // set button active classes
    this.set_button_active_class()

    // on turbo:before-fetch-request, show loader
//    document.addEventListener("turbo:before-fetch-request", ()=> {
//      if(this.propertyListLoader) this.propertyListLoader.classList.remove("hidden")
//    })

    document.addEventListener("turbo:before-frame-render", () => {
      // on turbo frame load always hide loader
      if(this.loaderOutlet) this.loaderOutlet.show()

      // set pagination
      this.set_pagination()

      // on frame load reset map
      this.reset_map()

      // scroll to properties top on properties frame load
      this.scroll_to_properties_top()
    })

    document.addEventListener("turbo:frame-render", () => {
        if(this.loaderOutlet) this.loaderOutlet.hide()
    })

    this.satelliteButton.addEventListener("click", (event) => {
      // on satellite button click toggle satellite mode on map
      if(this.satelliteButton)
        this.toggle_satellite()

      // on click set this.set_button_active_class()
      this.set_button_active_class()
    })

    // document.addEventListener("turbo:frame-render", () => {
    //   document.addEventListener("click", (event) => {
    //     // on click if a dropdown is open and the element or a parent don't have the class "sm-sub" or is not a live search button prevent default of the clicked element and close all tabs
    //     if (!this.element_is_a_dropdown(event.target) && !this.element_is_dropdown_button(event.target)) {
    //       event.preventDefault();
    //       this.close_all_tabs();
    //     }
    //   })
    // });
  }

  handleSubmit(event) {
    event.preventDefault()
    this.refresh_properties()
  }

  element_is_a_dropdown(element){
    // If the class "sm-sub" does not exist in the document, return null
    if (!document.querySelector('.sm-sub')) {
      return false;
    }

    // return true or false if the element or an ancestor has the class "sm-sub" or "live-button"
    while (element) {
      if (element.classList.contains('sm-sub')) {
        return true;
      }
      element = element.parentElement;
    }
    return false;
  }

  element_is_dropdown_button(element){
    // Check if the element has the class 'live-button'
    if (element.classList.contains('live-button')) {
      // If the element has the class 'live-button', return true
      return true;
    }

    // If the element does not have the class 'live-button', return false
    return false;
  }

  dropdown_is_open() {
    // Select all dropdown menus in the document
    const dropdowns = document.querySelectorAll('.sm-sub');

    // Check if any dropdown menu is currently open
    for (let i = 0; i < dropdowns.length; i++) {
      // Log the current dropdown menu and whether it is open
      console.log(`Dropdown ${i}: ${dropdowns[i].classList.contains('hidden') ? 'Closed' : 'Open'}`);

      if (!dropdowns[i].classList.contains('hidden')) {
        // If a dropdown menu is open, return true
        return true;
      }
    }


    // If no dropdown menu is open, return false
    return false;
  }

  display_mobile_region(event){
    const element = document.querySelector("#mobile-filters-location-search")
    const form = element.closest("form")

    if(element && form)
      form.classList.remove("hidden");
      element.classList.remove("hidden");
  }

  clear_search(event) {
    event.preventDefault()

    let pre_url   = this.formTarget.getAttribute("action")
    let visit_url = pre_url + "?region=all"

    Turbo.visit(visit_url, {frame: "ls-properties"})
  }

  set_instant(event){
    event.currentTarget.classList.toggle("active")

    this.refresh_properties()
  }

  set_flexible(event){
    event.currentTarget.classList.toggle("active")

    this.refresh_properties()
  }

  set_luxury(event){
    event.currentTarget.classList.toggle("active")

    this.refresh_properties()
  }

  set_pagination(){
    let url_params = window.location.search

    let pagination_links = document.querySelectorAll("#third-step-properties .pagination .page a")
    let pagination_next_link        = document.querySelector("#third-step-properties .pagination .next a")
    let pagination_last_link        = document.querySelector("#third-step-properties .pagination .last a")
    let pagination_previous_link    = document.querySelector("#third-step-properties .pagination .prev a")
    let pagination_first_link       = document.querySelector("#third-step-properties .pagination .first a")

    if(!pagination_links) return

    pagination_links.forEach((link) => {
      link.addEventListener("click", (event) => {
        event.preventDefault()
        event.stopPropagation()
        this.refresh_properties(false, link.textContent)
      })
    })

    // if click on next link we check if we have a page param in the url
    // if not the page is 1, if yes we increment the page param
    if(pagination_next_link){
        pagination_next_link.addEventListener("click", (event) => {
            event.preventDefault()
            event.stopPropagation()

            let page = 2

            if(url_params.includes("page=")){
              let page_param = url_params.match(/page=(\d+)/)
              page = parseInt(page_param[1]) + 1
            }

            this.refresh_properties(false, page)
        })
    }

    // if click on "last" link we find which is the last page we set the page param to that value
    // and we refresh properties

    if(pagination_last_link){
      pagination_last_link.addEventListener("click", (event) => {
        event.preventDefault()
        event.stopPropagation()

        let pages = document.querySelectorAll("#third-step-properties .pagination .page")

        if(pages){
          let last_page = pages[pages.length - 1].querySelector("a").textContent
          this.refresh_properties(false, last_page)
        }
      })
    }

    // if click on previous link we check if we have a page param in the url
    // if not we do nothing, if yes we decrement the page param and refresh properties
    // if the next previous is 1 we remove the page param and refresh properties
    if(pagination_previous_link){
      pagination_previous_link.addEventListener("click", (event) => {
        event.preventDefault()
        event.stopPropagation()

        let page = 1

        if(url_params.includes("page=")){
          let page_param = url_params.match(/page=(\d+)/)
          page = parseInt(page_param[1]) - 1
        }

        if(page == 1){
          this.refresh_properties(false, false)
        }else{
          this.refresh_properties(false, page)
        }
      })
    }



    // if click on first link we check if we have a page param in the url
    // if not we do nothing, if yes we remove the page param and refresh properties
    if(pagination_first_link){
      pagination_first_link.addEventListener("click", (event) => {
        event.preventDefault()
        event.stopPropagation()

        if(url_params.includes("page=")){
          this.refresh_properties(false, false)
        }
      })
    }
  }


  refresh_properties(watched = false, page = false, limit = false) {
    const pre_url = this.formTarget.getAttribute("action")

    let final_url = ""
    let properties_url = ""
    let url_params = []

    const property_types= this.get_property_types()
    const amenities     = this.get_amenities()
    const services      = this.get_services()

    // add region to url
    if(this.hasRegionTarget)
      url_params.push("&region=" + this.regionTarget.getAttribute("data-slug"))

    // add property types to url
    if(property_types && property_types.length > 0)
      url_params.push("&types="+ property_types.join(","))

    // add guests to url
    if(this.hasGuestsTarget && this.guestsTarget.value != "" && this.guestsTarget.value != 0)
      url_params.push("&guests=" + this.guestsTarget.value)

    // add amenities to url
    if(amenities.length > 0)
      url_params.push("&amenities=" + amenities.join(","))

    // add services to url
    if(services.length > 0)
      url_params.push("&services=" + services.join(","))

    // add bedrooms to url
    if(this.hasBedroomsTarget && this.bedroomsTarget.value != "" && this.bedroomsTarget.value != 0)
      url_params.push("&bedrooms=" + this.bedroomsTarget.value)

    // add bathrooms to url
    if(this.hasBathroomsTarget && this.bathroomsTarget.value != "" && this.bathroomsTarget.value != 0)
      url_params.push("&bathrooms=" + this.bathroomsTarget.value)

    // add price start to url
    if(this.hasPriceFromTarget && this.priceFromTarget.value != "")
      url_params.push("&from=" + this.priceFromTarget.value)

    // add price end to url
    if(this.hasPriceToTarget && this.priceToTarget.value != "")
      url_params.push("&to=" + this.priceToTarget.value)

    // add checkin and checkout to url if they are not the same
    if(this.hasCheckinTarget && this.checkinTarget.value != "" && this.hasCheckoutTarget && this.checkoutTarget.value != "") {
      if (this.checkinTarget.value !== this.checkoutTarget.value){
        url_params.push("&checkin=" + this.checkinTarget.value)
        url_params.push("&checkout=" + this.checkoutTarget.value)
      }
    }

    // add flexible to url
    if(this.hasFlexibleBookTarget && this.flexibleBookTarget.classList.contains("active"))
      url_params.push("&cancellation_policy=" + this.flexibleBookTarget.getAttribute('slug'))

    // add instant to url
    if(this.hasInstantBookTarget && this.instantBookTarget.classList.contains("active"))
      url_params.push("&instant=true")

    // add luxury to url
    if(this.hasLuxuryBookTarget && this.luxuryBookTarget.classList.contains("active"))
      url_params.push("&luxury=true")

    // add sort order to url
    if(this.hasSortOrderTarget){
      this.sortOrderTargets.forEach(function(sort_order){
        if(sort_order.classList.contains("active"))
          url_params.push("&sort_order=" + sort_order.getAttribute("status"))
      })
    }

    // add watched property slugs to url
    if(watched === true)
      url_params.push("&watched="+ this.get_markers_slug_inside_map_view_port())

    // add page to url
    if(page) url_params.push("&page="+ page)

    // add limit to url
    if(limit) url_params.push("&limit="+ limit)

    // preparing url params
    if(url_params.length > 0)
      url_params = url_params.join("")

    if (url_params.length > 0 && url_params.charAt(0) == "&")
      url_params = url_params.replace("&", "?")

    // set final url and properties url
    final_url       = pre_url + url_params
    properties_url  = "/third-step-properties" + url_params

    if(!watched) this.get_markers()

    // add final url to form action
    Turbo.visit(properties_url, {frame: "third-step-properties"})
    history.pushState(null, null, final_url)
  }

  set_sort_order(event) {
    let sort_order_fields = document.querySelectorAll(".s-sort-by")

    if(!sort_order_fields) return

    sort_order_fields.forEach((field) => {field.classList.remove("active")})

    event.currentTarget.classList.add("active")
    this.refresh_properties()
  }

  get_property_types() {
    let types = []

    this.propertyTypeTargets.forEach(function(checkbox) {
      if(checkbox.checked) types.push(checkbox.value)
    })

    return types
  }

  get_amenities(){
    let amenities = []

    this.amenitiesTargets.forEach(function(checkbox) {
      if(checkbox.checked) amenities.push(checkbox.value)
    })

    return amenities
  }

  get_services(){
    let services = []

    this.servicesTargets.forEach(function(checkbox) {
      if(checkbox.checked) services.push(checkbox.value)
    })

    return services
  }

  get_markers(){
    // get current url params
    const url_params = window.location.search

    // get the markers and sed them to the map
    // axios.post(this.getPropertiesUrl + url_params, {format: "json"}).then(response => {
    //   this.set_markers(response.data)
    // })
  }

  open_tab(event) {
    this.close_all_tabs()

    event.currentTarget.closest(".sm-wrapper").querySelector(".sm-sub").classList.remove("hidden")
    // if element is placeholderGuestsTarget dont add active class to button
    if(event.currentTarget !== this.placeholderGuestsTarget)
      event.currentTarget.classList.add("active")
  }

  close_tab(){
    event.currentTarget.closest(".sm-wrapper").querySelector(".sm-sub").classList.add("hidden")

    this.set_button_active_class()
  }

  toggle_tab(event) {
    if(event.currentTarget.closest(".sm-wrapper").querySelector(".sm-sub").classList.contains("hidden"))
        this.open_tab(event)
     else
        this.close_tab(event)
  }

  close_all_tabs(){
    document.querySelectorAll(".sm-sub").forEach(function(element) {
      if(!element.classList.contains("hidden"))
        element.classList.add("hidden")
    })
  }

  set_button_active_class(){
    // if has checked amenities add active class to amenities button
    if(this.hasAmenitiesTarget) {
      const checked_amenities = this.amenitiesTargets.filter((amenity) => amenity.checked)

      if (checked_amenities.length > 0)
        this.amenitiesButtonTarget.classList.add("active")
      else
        this.amenitiesButtonTarget.classList.remove("active")
    }

    // if has checked services add active class to services button
    if(this.hasServicesTarget) {
      const checked_services = this.servicesTargets.filter((service) => service.checked)

      if (checked_services.length > 0)
        this.serviceButtonTarget.classList.add("active")
      else
        this.serviceButtonTarget.classList.remove("active")
    }

    // if has set price from or price to add active class to price button
    if(this.hasPriceFromTarget || this.hasPriceToTarget) {
      if(this.priceFromTarget.value != "" || this.priceToTarget.value != "")
        this.priceButtonTarget.classList.add("active")
      else
        this.priceButtonTarget.classList.remove("active")
    }

    // if has checked property types add active class to property types button
    if(this.hasPropertyTypeTarget) {
      const checked_property_types = this.propertyTypeTargets.filter((property_type) => property_type.checked)

      if (checked_property_types.length > 0)
        this.propertyTypesButtonTarget.classList.add("active")
      else
        this.propertyTypesButtonTarget.classList.remove("active")
    }

    // if has checked bedrooms add active class to bedrooms button
    if(this.hasBedroomsTarget) {
      if(this.bedroomsTarget.value != "" && this.bedroomsTarget.value != 0)
        this.bedroomsButtonTarget.classList.add("active")
      else
        this.bedroomsButtonTarget.classList.remove("active")
    }

    // if has checked bathrooms add active class to bathrooms button
    if(this.hasBathroomsTarget) {
      if(this.bathroomsTarget.value != "" && this.bathroomsTarget.value != 0)
        this.bathroomsButtonTarget.classList.add("active")
      else
        this.bathroomsButtonTarget.classList.remove("active")
    }

    // if has checked sort order add active class to sort order button
    if(this.hasSortOrderTarget) {
      const checked_sort_order = this.sortOrderTargets.filter((sort_order) => sort_order.classList.contains("active"))

      if (checked_sort_order.length > 0)
        this.sortByButtonTarget.classList.add("active")
      else
        this.sortByButtonTarget.classList.remove("active")
    }
  }

  input_increase(event) {
    const input = event.currentTarget.closest(".input-wrapper").querySelector("input")
    input.value = parseInt(input.value) + 1

    if(this.hasPlaceholderGuestsTarget && event.currentTarget.closest(".sm-wrapper").querySelector("input").classList.contains("s-guest-button"))
      this.placeholderGuestsTarget.value = input.value
  }

  input_decrease(event) {
    const input = event.currentTarget.closest(".input-wrapper").querySelector("input")
    const current_value = parseInt(input.value)

    if(current_value > 0)
      input.value = current_value - 1

    if (this.hasPlaceholderGuestsTarget && event.currentTarget.closest(".sm-wrapper").querySelector("input").classList.contains("s-guest-button"))
      if (input.value != 0)
        this.placeholderGuestsTarget.value = input.value
      else
        this.placeholderGuestsTarget.value = input.getAttribute("placeholder")
  }

  init_datepicker(){
    const datepicker = mobiscroll.datepicker(".live-mobiscroll", {
      themeVariant: 'light',
      theme: "material",
      controls: ['calendar'],
      select: 'range',
      display: 'center',
      pages: 2,
      dateFormat: 'DDD DD MMM, YYYY',
      calendarType: 'month',
      returnFormat: 'moment',
      rangeStartLabel: 'Check in',
      rangeEndLabel: 'Check out',
      min: this.min,
      max: this.max,
      onInit: (event, inst) => {
        if (this.hasCheckinTarget && this.checkinTarget.value && this.hasCheckoutTarget && this.checkoutTarget.value) {
          let check_in    = (moment(this.checkinTarget.value).format('YYYY/MM/DD'))
          let check_out   = (moment(this.checkoutTarget.value).format('YYYY/MM/DD'))

          if(check_in === check_out){
            event.inst.setVal(null)
            return false
          }

          inst.setVal([moment(this.checkinTarget.value).toDate(), moment(this.checkoutTarget.value).toDate()])
        }
      },
      onChange: (event, inst) => {
        let check_in    = (moment(event.value[0]).format('YYYY/MM/DD'))
        let check_out   = (moment(event.value[1]).format('YYYY/MM/DD'))

        // if checkin and checkout are the same, set value to null and return false
        if(check_in === check_out) event.inst.setVal(null)

        if(this.hasCheckinTarget)
          this.checkinTarget.value = check_in

        if(this.hasCheckoutTarget)
          this.checkoutTarget.value = check_out

        if(check_in && check_out) this.refresh_properties()
      }
    });
  }

  init_map(){
    // Map api key
    mapboxgl.accessToken = 'pk.eyJ1IjoicGF4b3NyZXRyZWF0cyIsImEiOiJjbDcwbW9vb3kwZTR4M29zYmJoMmlqcmJoIn0.tKMf62W9VJTdcLspkoMrTg';

    this.map_properties().then(([properties, coordinates, zoom]) => {
      // init map and add coordinates and zoom level
      this.searchMap = new mapboxgl.Map({
        container: 'search-map', // container ID
        style: 'mapbox://styles/mapbox/streets-v11', // style URL
        center: coordinates, // starting position [lng, lat]
        zoom: zoom, // starting zoom
        projection: 'globe', // display the map as a 3D globe
      });

      // set properties markers
      this.set_markers(properties)

      // set map controls
      this.searchMap.addControl(new mapboxgl.NavigationControl()).scrollZoom.disable();

//      this.searchMap.on("dragend", () => {
//        this.refresh_properties(true, false)
//      })
//
//      this.searchMap.on("zoomend", () => {
//        this.refresh_properties(true, false)
//      })
    })
  }

  async map_properties(){
    const queryParams = new URLSearchParams(window.location.search);

    const response = await post("/properties-for-map?" + queryParams.toString(), {responseKind: "json"})
    if (response.ok) {
        const data = await response.json;
        return [data.properties, data.coordinates, data.zoom];
    }
  }

  reset_map(){
    // get current url params and check if we have "watched" param
    // if yes we return
    const url_params = window.location.search
    if(url_params.includes("watched")) return

    // The the url changes cause of a filter we get again the properties which are based on the map region
    this.map_properties().then(([properties, coordinates, zoom]) => {
      // set properties markers
      this.set_markers(properties)

      // move map to new coordinates and zoom level
      this.searchMap.flyTo({center: coordinates, zoom: zoom})
    })
  }

  set_markers(properties){
    let markers_array = []
    let sthis = this

    // remove all markers
    if(this.searchMapMarkers) {
      this.searchMapMarkers.forEach(function (marker) {
        marker.remove()
      })
    }

    // return of properties is empty
    if(!properties) return

    properties.forEach(function(property) {
      const latitude  = property["latitude"];
      const longitude = property["longitude"];

      // next if latitude or longitude are not present
      if(!latitude || !longitude) return

      const slug      = property["slug"];
      let html_popup  = new mapboxgl.Popup({ maxWidth: 300 }).setHTML(property["html"]);

      if(latitude && longitude) {
        let marker  = new mapboxgl.Marker().setLngLat([latitude, longitude]);

        marker.slug = slug

        if(html_popup)
          marker.setPopup(html_popup)

        marker.addTo(sthis.searchMap)

        markers_array.push(marker)
      }
    });

    this.searchMapMarkers = markers_array
  }

  get_markers_slug_inside_map_view_port(){
    let searchMapMarkers = this.searchMapMarkers
    let slugs = []

    if(searchMapMarkers && searchMapMarkers.length > 0){
      for (let i = 0; i < searchMapMarkers.length; i++) {
        if (this.searchMap.getBounds().contains(searchMapMarkers[i]._lngLat))
          slugs.push(searchMapMarkers[i]["slug"])
      }
    }
    return slugs.join(",")
  }

  toggle_satellite(){
      if (this.searchMap.getStyle().name === 'Mapbox Streets') {
        this.searchMap.setStyle('mapbox://styles/mapbox/satellite-v9');
        // add active class to button
        this.satelliteButton.classList.add("style-toggle-active")
      } else {
        this.searchMap.setStyle('mapbox://styles/mapbox/streets-v11');
        this.satelliteButton.classList.remove("style-toggle-active")
      }
  }

  scroll_to_properties_top(){
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  increase_limit(event){
    const limit = parseInt(event.currentTarget.dataset.limit)
    this.refresh_properties(true, false, limit)
  }
}
