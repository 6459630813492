import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="country-phone-list"
export default class extends Controller {
  static targets = ["wrapper", "searchBox", "listItem", "fieldToSet", "dropdown"]

  connect() {
    axios.defaults.headers.common['Accept']       = "text/vnd.turbo-stream.html"
    axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    this.getCountryPhoneUrl                       = "/resources/get_country_phones"
  }

  get_country_phone_list(event) {
    const dom_id = event.currentTarget.closest("div").getAttribute("id")
    axios.post(this.getCountryPhoneUrl, {dom_id: dom_id}).then(function (response) {
      Turbo.renderStreamMessage(response.data)
    })
  }

  search_list_and_show_results(event) {
    // search list div's using search box value and show divs with related results and hide the rest
    const searchBoxValue = event.currentTarget.value.toLowerCase()
    this.listItemTargets.forEach((listItem) => {
        if (listItem.innerText.toLowerCase().includes(searchBoxValue)) {
            listItem.classList.remove("hidden")
        } else {
            listItem.classList.add("hidden")
        }
    })
  }

  set_to_field(event) {
      // set selected event element value attribute to fieldToSet target value
      this.fieldToSetTarget.value = event.currentTarget.getAttribute("value")

      // and delete dropdown
      this.dropdownTarget.remove()
  }
}
