import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="host-with-us"
export default class extends Controller {
  static outlets = ["service-modal"]

  connect() {
    this.state_service_modal()
  }

  state_service_modal() {
          let serviceModals = document.querySelectorAll(".services-modal-link")

          if (serviceModals) {
              serviceModals.forEach((serviceModal) => {
                    serviceModal.addEventListener("click", () => {
                      this.serviceModalOutlet.open()
                    })
              })
          }
  }
}
