import { Controller } from "@hotwired/stimulus"
import { post } from '@rails/request.js'

// Connects to data-controller="checkout"
export default class extends Controller {
  static outlets = [
      "sign-in",
      "steps",
      "common",
      "stripe"
  ]

  static targets = [
      "form",
      "personalDetailsValidationErrors",
      "paymentOptionsValidationErrors",
      "paymentOptionButtons",
      "emailField",
      "instructionsValidationErrors",
      "orderTerms"
  ]

  connect() {
    this.validatePersonalDetailsUrl = document.querySelector('meta[name="validate-personal-details"]').content
    this.validatePaymentOptionsUrl  = document.querySelector('meta[name="validate-payment-options"]').content
    this.validateInstructionsUrl    = document.querySelector('meta[name="validate-instructions"]').content
    this.classesForErrors           = "bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded capitalize"
  }

  open_sign_in(){
    this.signInOutlet.open()
  }

  validate_personal_details(event){
    event.preventDefault()

    post(this.validatePersonalDetailsUrl, {
        body: new FormData(this.formTarget),
        responseKind: 'json'
    }).then(response => {
          if (response.ok) {
            return response.json; // Access the parsed JSON data here
          } else {
            throw new Error(`Request failed with status ${response.status}`);
          }
    }).then(data => {
        if(data.errors){
            data.errors.forEach(error => {
                error[Object.keys(error)[0]].forEach(message => {
                    let element = document.querySelector(`#${Object.keys(error)[0]}_errors`)
                    if(element) element.innerHTML += message
                })
            })
        }
        if(data.status) this.stepsOutlet.next()
    })
  }

  validate_payment_options(){
   const error = `<div class="${this.classesForErrors}">Please select a payment option</div>`;

   // if any paymentOptionButtonsTargets dont have active class add error in paymentOptionsValidationErrorsTarget
    if(this.paymentOptionButtonsTargets.some(button => button.classList.contains('active'))){
      this.paymentOptionsValidationErrorsTarget.innerHTML = '';
      this.stepsOutlet.next()
    } else {
      this.paymentOptionsValidationErrorsTarget.innerHTML = error;
    }

  }

  validate_payment_methods(){
    let sthis = this
    this.stripeOutlet.stripe.confirmCardSetup(this.stripeOutlet.client_secret, {
                payment_method: {
                    card: this.stripeOutlet.cardNumber,
                    billing_details: {
                        email: this.emailFieldTarget.value
                    }
                }
            }).then((result) => {
                if(result.error){
                    sthis.stripeOutlet.cardErrorsTarget.textContent = result.error.message
                } else {
                    sthis.stripeOutlet.paymentHashTarget.value = result.setupIntent.payment_method
                    sthis.stepsOutlet.next()
                }
            })
  }

  validate_instructions(event){
    event.preventDefault()
    // if orderTermsTarget is checked we proceed with submitting the form
    // else we display an error
    if(this.orderTermsTarget.checked){
      this.instructionsValidationErrorsTarget.innerHTML = '';
      this.formTarget.submit()
    } else {
      this.instructionsValidationErrorsTarget.innerHTML = `<div class="${this.classesForErrors}">Please accept the terms and conditions</div>`;
    }
  }

  createErrorHtml(errors) {
      let html = '';
      errors.forEach(error => {
          html += `<div class="${this.classesForErrors}">${error}</div>`;
      });
      return html;
  }
}
