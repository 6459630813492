import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="delete-modal"
export default class extends Controller {
    static targets = [
        "wrapper",
        "inner"
    ]

    close() {
        this.wrapperTarget.closest("div").remove()
    }

    open() {
        this.innerTarget.classList.add("animate__fadeInDown")
        this.wrapperTarget.classList.remove("hidden")
    }
}
