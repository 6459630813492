import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static outlets = [
    "sign-up",
    "forgot-password"
  ]

  static targets = [
    "wrapper",
    "inner"
  ]

  connect() {}

  open() {
    this.wrapperTarget.classList.remove("hidden")
    this.innerTarget.classList.add("animate__fadeInDown")
  }

  close() {
    this.innerTarget.classList.remove("animate__fadeInDown")
    this.wrapperTarget.classList.add("hidden")
  }

  open_create_account(){
    this.close()
    this.signUpOutlet.open()
  }

  open_forgot_password(){
    this.close()
    this.forgotPasswordOutlet.open()
  }
}
