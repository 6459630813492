import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="service-modal"
export default class extends Controller {
  static targets = ["wrapper"]

  connect() {
  }

  open() {
    this.wrapperTarget.classList.remove("hidden")
  }

  close() {
    this.wrapperTarget.classList.add("hidden")
  }
}
