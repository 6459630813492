export const userCalendarMixins = controller => {
    Object.assign(controller, {
        async get_rates() {
            this.rates = await axios.post(this.calendarGetRatesUrl, {identifier: this.identifierValue, format: "json"}).then((response)=> {
                return response.data
            })
        },
        async get_gross_labels() {
            this.gross_labels = await axios.post(this.calendarGrossLabelsUrl, {identifier: this.identifierValue, format: "json"}).then((response)=> {
                return response.data
            })
        },

        resetValidationsOnForms() {
            let timer = 2000;

            setTimeout(() => {
                $('.simple_form:not(.dont-validate)').each(function () {
                    $(this).validate({
                        ignore: [],
                        invalidHandler: function (event, validator) {
                            $.each(validator.errorList, function (index, error) {
                                return false;
                            });
                        },
                    })
                })
            }, timer)
        },
        convert_text_to_labels() {
            this.create_minimum_stay_labels()
            this.create_reservation_labels()

            return true
        },
        create_reservation_labels() {
            const check_in_cells = document.querySelectorAll(".cell-check-in")
            const booked_cells  = document.querySelectorAll(".cell-booked")
            const check_out_cells = document.querySelectorAll(".cell-check-out")
            const text_cells    = document.querySelectorAll(".mbsc-calendar-label-text")

            // delete all labels inside booked cells
            if(check_in_cells){
                for(let i = 0; i < check_in_cells.length; i++)
                    this.create_check_in_reservation_label(check_in_cells[i])
            }
            if(check_out_cells){
                for(let i = 0; i < check_out_cells.length; i++)
                    this.create_check_out_reservation_label(check_out_cells[i])
            }
            if(booked_cells){
                for(let i = 0; i < booked_cells.length; i++)
                    this.create_booked_reservation_label(booked_cells[i])
            }
            if(text_cells){
                for(let i = 0; i < text_cells.length; i++)
                    this.modify_text_cells(text_cells[i])
            }
        },
        modify_text_cells(text_cell) {
            // if text_cell contains only text and not any divs add margin-top 10 to it style
            if(text_cell.textContent.trim().length > 0 && text_cell.querySelectorAll("div").length === 0)
                text_cell.style.marginTop = "50px"
        },
        create_check_in_reservation_label(cell) {
            const wrapper   = cell.querySelector(".mbsc-calendar-labels")

            if (!wrapper) return

            const inner     = wrapper.querySelector(".mbsc-calendar-label-wrapper")

            if (!inner) return

            const field     = inner.querySelector(".mbsc-calendar-label-text")

            if(field){
                let parts = field.textContent.split("+")

                if (parts.length < 4) return

                let image_url   = parts[0].split("::")[1]
                let guests      = parts[1].split("::")[1]
                let name        = parts[2].split("::")[1]
                let total       = parts[3].split("::")[1]

                let div = document.createElement("div")

                if(!cell.classList.contains("cell-check-out"))
                    div.style.marginLeft = "11%"

                if(cell.classList.contains("mbsc-disabled") && !cell.classList.contains("keep-black")) {
                    div.classList.add("border", "text-black")
                    div.style.color = "#979797"
                    div.style.borderColor = "#979797"
                    div.style.borderWidth = "1px"
                    div.style.borderRightWidth = "0px"
                    div.style.backgroundColor = "#f9f9f9"
                } else
                    div.classList.add("bg-black", "text-white")

                div.classList.add("cell-reservation", "flex", "flex-row", "items-center", "gap-x-3", "p-1", "rounded-l-3xl", "text-sm", "w-full")

                let new_image = document.createElement("img")
                new_image.src = image_url
                new_image.classList.add("rounded-full", "w-10", "h-10")

                let name_div = document.createElement("div")
                name_div.textContent = name

                let guest_div = document.createElement("div")
                guest_div.textContent = guests + " Guests"

                let total_div = document.createElement("div")
                total_div.textContent = "€" + total

                div.append(new_image)
                div.append(name_div)
                div.append(guest_div)
                div.append(total_div)

                // empty field and add div
                field.textContent = ""
                field.append(div)
            }
        },
        create_booked_reservation_label(cell) {
            const wrapper   = cell.querySelector(".mbsc-calendar-labels")

            if (!wrapper) return

            const inner     = wrapper.querySelector(".mbsc-calendar-label-wrapper")

            if (!inner) return

            const field     = inner.querySelector(".mbsc-calendar-label-text")

            if(field){
                let div = document.createElement("div")

                div.classList.add("cell-reservation")

                if(cell.classList.contains("mbsc-disabled") && !cell.classList.contains("keep-black")) {
                    div.classList.add("bg-white", "border")
                    div.style.color = "#979797"
                    div.style.borderColor = "#979797"
                    div.style.borderWidth = "1px"
                    div.style.borderRightWidth = "0px"
                    div.style.borderLeftWidth = "0px"
                    div.style.backgroundColor = "#f9f9f9"
                } else {
                    if(cell.classList) {
                        for (const className of cell.classList) {
                            if (className.startsWith('reservation-')) {
                                const class_full_name = "reservation-" + className.split('-')[1];
                                const first_element = document.querySelector("." + class_full_name)

                                if(first_element.classList.contains("mbsc-disabled") && !first_element.classList.contains("keep-black")) {
                                    div.classList.add("bg-white", "border")
                                    div.style.color = "#979797"
                                    div.style.borderColor = "#979797"
                                    div.style.borderWidth = "1px"
                                    div.style.borderRightWidth = "0px"
                                    div.style.borderLeftWidth = "0px"
                                    div.style.backgroundColor = "#f9f9f9"
                                } else {
                                    div.classList.add("bg-black")
                                }

                                break;
                            }
                        }
                    } else
                        div.classList.add("bg-black")
                }


                let div_inner = document.createElement("div")
                div_inner.classList.add("rounded-full", "w-10", "h-12")

                div.append(div_inner)

                field.textContent = ""
                field.append(div)
            }
        },
        create_check_out_reservation_label(cell) {
            const wrapper   = cell.querySelector(".mbsc-calendar-labels")

            if (!wrapper) return

            const inner     = cell.querySelector(".mbsc-calendar-labels .mbsc-calendar-label-wrapper")
            const field     = cell.querySelector(".mbsc-calendar-labels .mbsc-calendar-label-wrapper .mbsc-calendar-label-text")

            if(!inner || field){
                let div = document.createElement("div")

                if(cell.classList.contains("mbsc-disabled") && !cell.classList.contains("keep-black")) {
                    div.classList.add("border", "text-black")
                    div.style.color = "#979797"
                    div.style.borderColor = "#979797"
                    div.style.borderWidth = "1px"
                    div.style.borderLeftWidth = "0px"
                } else {
                    if(cell.classList) {
                        for (const className of cell.classList) {
                            if (className.startsWith('reservation-checkout-')) {
                                const class_full_name = "reservation-" + className.split('-')[2];
                                const first_element = document.querySelector("." + class_full_name)

                                if(first_element.classList.contains("mbsc-disabled") && !first_element.classList.contains("keep-black")) {
                                    div.classList.add("bg-white", "border")
                                    div.style.color = "#979797"
                                    div.style.borderColor = "#979797"
                                    div.style.borderWidth = "1px"
                                    div.style.borderLeftWidth = "0px"
                                    div.style.backgroundColor = "#f9f9f9"
                                } else {
                                    div.classList.add("bg-black")
                                }

                                break;
                            }
                        }
                    } else
                        div.classList.add("bg-black")
                }


                div.classList.add("cell-reservation", "cell-checkout", "flex", "flex-row", "items-center", "gap-x-3", "px-1", "py-6", "rounded-r-3xl", "text-sm")

                if(!inner) {
                    div.classList.add("h-12")
                    div.style.marginTop = "25px"
                    div.style.marginLeft = "-22px"
                }

                if(cell.classList.contains("cell-check-in") && cell.classList.contains("cell-check-out")) {
                    if(field)
                        field.classList.add("flex", "flex-row", "w-full", "gap-x-3")
                    div.classList.add("w-20")
                    if(inner)
                        inner.style.marginLeft = "-16px"
                } else {
                    div.style.marginRight = "50%"
                }

                // empty field and add div
                if(field)
                    field.prepend(div)
                if(!inner)
                    wrapper.prepend(div)
            }
        },
        create_minimum_stay_labels() {
            const minimum_stay_cells= document.querySelectorAll(".cell-minimum-stay-label")
            const cells= document.querySelectorAll(".mbsc-calendar-cell")

            // delete the previous labels
            // if(minimum_stay_cells){
            //     for(let i = 0; i < minimum_stay_cells.length; i++) {
            //         minimum_stay_cells[i].remove()
            //     }
            // }

            if(cells.length === 0) return

            for(let i = 0; i < cells.length; i++) {
                const cell          = cells[i]

                if(cell.querySelector(".cell-minimum-stay-label")) continue

                const inner         = cell.querySelector(".mbsc-calendar-cell-inner")
                const label_field   = cell.querySelector(".mbsc-calendar-label-text")

                if(!label_field) continue
                if(!label_field.textContent.includes("ms:") || label_field.textContent.includes("multi:")) continue

                const minimum_stay= this.get_minimun_stay_from_label_field(label_field)
                const min_stay_label = this.create_minimum_stay_label(minimum_stay)

                inner.appendChild(min_stay_label)
            }
        },
        get_minimun_stay_from_label_field(label_field) {
            let label_text    = label_field.textContent
            let first_filter  = label_text.split(" ")[1]
            let minimum_stay  = parseInt(first_filter.split(":")[1])

            // delete the ms:#{number} from the label
            label_field.textContent = label_text.replace(first_filter, "")

            return minimum_stay
        },
        create_minimum_stay_label(minimum_stay) {
            let div = document.createElement("div")
            div.classList.add("absolute", "top-0", "right-1", "cell-minimum-stay-label")

            let wrapper = document.createElement("div")
            wrapper.classList.add("flex", "flex-row", "items-center", "justify-center", "gap-x-1")

            let icon = document.createElement("i")
            icon.classList.add("fa-solid", "fa-door-open")
            icon.style.color = "#4270a2"

            let div_m = document.createElement("div")
            div_m.textContent = minimum_stay
            div_m.style.color = "#4270a2"

            div.prepend(wrapper)
            wrapper.append(div_m)
            wrapper.prepend(icon)


            return div
        }
    });
};