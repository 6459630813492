import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="stripe"
export default class extends Controller {
  static targets = [
    "cardErrors",
    "paymentOptionButtons",
    "splitPaymentsButton",
    "payInFullButton",
    "paymentHash"
  ]

  connect() {
    axios.defaults.headers.common['Accept']       = "text/vnd.turbo-stream.html"
    axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    this.stripe     = Stripe($('meta[name="stripe_public_key"]').attr('content'), {locale: $('meta[name="locale"]').attr('content')});
    this.elements   = this.stripe.elements();
    this.cardNumber = this.elements.create('cardNumber');
    this.cardExpiry = this.elements.create('cardExpiry');
    this.cardCvc    = this.elements.create('cardCvc');

    this.cardNumber.mount('#card-number');
    this.cardExpiry.mount('#card-expired-date');
    this.cardCvc.mount('#card-security-code');

    this.cardNumber.addEventListener('change', (event) => this.displayError(event));
    this.cardExpiry.addEventListener('change', (event) => this.displayError(event));
    this.cardCvc.addEventListener('change', (event) => this.displayError(event));

    this.createSetupIntent()
  }

  displayError(event) {
    this.cardErrorsTarget.textContent = event.error ? event.error.message : '';
  }

  createSetupIntent(){
    axios.post("/create-setup-intent", {format: "json"}).then((response) => {
      this.client_secret = response.data.client_secret
    })
  }

  pay_in_full_button(event) {
    this.remove_active_from_payment_option_buttons()
    this.payInFullButtonTarget.classList.add('active')
  }

  split_payments(event) {
    this.remove_active_from_payment_option_buttons()
    this.splitPaymentsButtonTarget.classList.add('active')
  }

  remove_active_from_payment_option_buttons() {
    this.paymentOptionButtonsTargets.forEach((button) => {
      button.classList.remove('active')
    })
  }
}
