import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin-petition"
export default class extends Controller {
  static targets = ["declineReasonWrapper"]

  connect() {
  }

  toggle_decline_reason(event) {
    if (this.hasDeclineReasonWrapperTarget && event.target.value == "Decline")
      this.declineReasonWrapperTarget.classList.remove("hidden")
    else
      this.declineReasonWrapperTarget.classList.add("hidden")
  }
}
