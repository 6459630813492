import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="checkout-split-payment-options"
export default class extends Controller {
  static targets = ["select"]

  connect() {
    axios.defaults.headers.common['Accept']         = "text/vnd.turbo-stream.html"
    axios.defaults.headers.common['X-CSRF-Token']   = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    this.displayInstallmentScheduleUrl              = document.querySelector("meta[name='display-installment-schedule']").getAttribute("content")
    this.show_installment_schedule_on_init()
  }

  show_installment_schedule_on_init() {
   axios.post(this.displayInstallmentScheduleUrl, { installments: this.selectTarget.value }).then((response)=> {
        Turbo.renderStreamMessage(response.data)
   })
  }

  show_installment_schedule(event) {
    axios.post(this.displayInstallmentScheduleUrl, { installments: event.currentTarget.value }).then((response)=> {
        Turbo.renderStreamMessage(response.data)
    })
  }
}
