import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="checkout-timeout"
export default class extends Controller {
  static targets = [
      "minutes",
      "seconds",
      "timeWidth",
      "newSearch"
  ]

  connect() {
    this.startTimer(2)
  }

  close(event) {
    event.currentTarget.closest('#checkout-timeout-modal').remove()
  }

  startTimer(duration_in_minutes) {
    let duration = duration_in_minutes * 60
    let timer = duration, minutes, seconds
    let interval = setInterval(() => {
      minutes = parseInt(timer / 60, 10)
      seconds = parseInt(timer % 60, 10)

      minutes = minutes < 10 ? `0${minutes}` : minutes
      seconds = seconds < 10 ? `0${seconds}` : seconds

      this.minutesTarget.textContent = minutes
      this.secondsTarget.textContent = seconds
      this.timeWidthTarget.style.width = `${(timer / duration) * 100}%`

      if (--timer < 0) {
        clearInterval(interval)
        this.timeout()
      }
    }, 1000)
  }

  timeout() {
    this.newSearchTarget.click()
  }
}
