import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sleeping-arrangements"
export default class extends Controller {
  static targets = [
    "guests",
    "bedrooms",
    "bathrooms",
    "otherRooms",
    "bedroomsList",
    "otherRoomsList",
    "propertyField",
    "roomItem",
    "capacityError"
  ]

  connect() {
    axios.defaults.headers.common['Accept']       = "text/vnd.turbo-stream.html"
    axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    this.addBedroomUrl                            = document.querySelector('meta[name="add-bedroom"]').getAttribute('content')
    this.addOtherUrl                              = document.querySelector('meta[name="add-other-room"]').getAttribute('content')
    this.property_id                              = document.querySelector('#property_id').value;
  }

  total_capacity() {
    let total_capacity = 0

    this.roomItemTargets.forEach((item) => {
      const capacity = parseInt(item.querySelector('.capacity').value)

      if(capacity > 0)
        total_capacity += capacity
    })

    return total_capacity
  }

  show_capacity_error() {
    this.capacityErrorTarget.classList.remove('hidden')
  }

  hide_capacity_error() {
    this.capacityErrorTarget.classList.add('hidden')
  }

  increase_guests() {
    this.guestsTarget.value = parseInt(this.guestsTarget.value) + 1
  }

  decrease_guests() {
    this.guestsTarget.value = parseInt(this.guestsTarget.value) - 1
  }

  increase_bathrooms() {
    this.bathroomsTarget.value = parseInt(this.bathroomsTarget.value) + 1
  }

  decrease_bathrooms() {
    this.bathroomsTarget.value = parseInt(this.bathroomsTarget.value) - 1
  }

  increase_bedrooms() {
    this.bedroomsTarget.value = parseInt(this.bedroomsTarget.value) + 1

    this.add_bedroom()
  }

  decrease_bedrooms() {
    if(this.bedroomsTarget.value <= 0) return

    this.bedroomsTarget.value = parseInt(this.bedroomsTarget.value) - 1
    this.remove_bedroom()
  }

  increase_other_rooms() {
    this.otherRoomsTarget.value = parseInt(this.otherRoomsTarget.value) + 1
    this.add_other_room()
  }

  decrease_other_rooms() {
    if(this.otherRoomsTarget.value <= 0) return

    this.otherRoomsTarget.value = parseInt(this.otherRoomsTarget.value) - 1
    this.remove_other_room()
  }

  add_bedroom() {
    axios.post(this.addBedroomUrl, {property_id: this.propertyFieldTarget.value}).then(function (response) {
      Turbo.renderStreamMessage(response.data)
    })
  }

  remove_bedroom() {
    let bedrooms  = document.querySelectorAll('.bedroom:not(.hidden)')

    if(!bedrooms) return

    let last_bedroom  = bedrooms[bedrooms.length - 1]

    if(!last_bedroom) return

    let destroy_input = last_bedroom.querySelector('input[name*="[_destroy]"]')

    if(destroy_input) {
      last_bedroom.classList.add('hidden')
      destroy_input.value = "1"
      destroy_input.classList.add('hidden')
    } else
      last_bedroom.remove()
  }

  add_other_room() {
    axios.post(this.addOtherUrl, {property_id: this.propertyFieldTarget.value}).then(function (response) {
      Turbo.renderStreamMessage(response.data)
    })
  }

  remove_other_room(event) {
    let last_other_rooms = document.querySelectorAll('.other-room:not(.hidden)')
    let last_other_room  = last_other_rooms[last_other_rooms.length - 1]

    if(last_other_rooms && last_other_room) {
      let destroy_input = last_other_room.querySelector('input[name*="[_destroy]"]')

      if (destroy_input) {
        last_other_room.classList.add('hidden')
        destroy_input.value = "1"
        destroy_input.classList.add('hidden')
      } else
        last_other_room.remove()
    }
  }

  validate_rooms(event) {
    if(this.hasRoomItemTarget){
      const requiredCapacity = parseInt(this.guestsTarget.value)
      const totalCapacity    = this.total_capacity()
      let error              = false

      this.roomItemTargets.forEach((item) => {
        const bedTypeError  = item.querySelector('.bed-type-error')
        const capacityError = item.querySelector('.capacity-error')

        bedTypeError.classList.add('hidden')
        capacityError.classList.add('hidden')

        const capacity = parseInt(item.querySelector('.capacity').value)
        const furnitures        = item.querySelectorAll('.number:not(.capacity)')

        let totalFurnitures = 0

        furnitures.forEach(function (furniture) {
          totalFurnitures += parseInt(furniture.value)
        })

        if(capacity === 0) {
          capacityError.classList.remove('hidden')
          error = true
        } else {


            if(requiredCapacity < capacity) {
              capacityError.classList.remove('hidden')
              this.scroll_to_element(capacityError)
              error = true
            }
        }

        if(totalFurnitures === 0) {
          bedTypeError.classList.remove('hidden')
          this.scroll_to_element(bedTypeError, 20)
          error = true
        }

        // if error is true stop current form submission
        if(error) {
          event.preventDefault()
        }
      })

      // if total capacity is lower than required capacity show capacity error
      if(totalCapacity != requiredCapacity) {
        this.show_capacity_error()
        this.scroll_to_element(this.capacityErrorTarget)

        event.preventDefault()
      }
    }
  }

  scroll_to_element(element, offset) {
    element.scrollIntoView({top: offset, behavior: "smooth", block: "center", inline: "nearest"});
  }
}
