import { Controller } from "@hotwired/stimulus"
import Sortable from 'sortablejs';
import { Fancybox } from "@fancyapps/ui";

// Connects to data-controller="property"
export default class extends Controller {
  static values = {
      identifier: String,
      marketLat: String,
      marketLng: String
  }

  static targets = [
      "fullDescription",
      "amenities",
      "mapField",
      "listOfPhotos",
      "photoForm",
      "photoSaveButton",
      "addPhotoForm",
      "addPhotosInput",
      "addPhotosButton",
      "addPhotoSaveButton",
      "photoPreview",
      "adminSaveButton",
      "checkin",
      "checkout",
      "guests",
      "propertyBookingForm",
      "propertyBookingFormSubmit",
      "submitted",
      "datepicker",
      "inlineDatepicker",
      "bookingFormWrapper",
      "maxPhotosError",
      "inlineSummaryDatesTitle",
      "inlineSummaryDates"
  ]

  connect() {
      axios.defaults.headers.common['Accept']       = "text/vnd.turbo-stream.html"
      axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

      let invalid_dates_url = document.querySelector("meta[name='datepicker-invalid-dates']")
      let property_rates_url= document.querySelector("meta[name='datepicker-get-rates']")
      let property_price_labels_url = document.querySelector("meta[name='datepicker-get-price-labels']")

      if(invalid_dates_url)
          this.datepickerInvalidDatesUrl = invalid_dates_url.getAttribute("content")

      if(property_rates_url)
          this.datepickerPropertyRatesUrl = property_rates_url.getAttribute("content")

      if(property_price_labels_url)
          this.datepickerPropertyPriceLabelsRatesUrl = property_price_labels_url.getAttribute("content")


      if(this.hasListOfPhotosTarget) this.init_sortable_images()
      if(this.hasMapFieldTarget) this.init_map()
      if(this.hasPropertyBookingFormTarget) this.set_booking_details_onload()
  }

  open_mobile_booking_form() {
      if(this.hasBookingFormWrapperTarget) this.bookingFormWrapperTarget.classList.remove("hidden");
  }

  close_mobile_booking_form() {
      if(this.hasBookingFormWrapperTarget) this.bookingFormWrapperTarget.classList.add("hidden");
  }

  submit_booking_form(event) {
      const submitted = this.propertyBookingFormTarget.querySelector('input[name="submitted"]')

      if(this.hasPropertyBookingFormTarget) {
          this.propertyBookingFormTarget.requestSubmit();
          if(submitted) submitted.remove();
      }

      document.addEventListener("turbo:submit-end", () => {
          this.propertyBookingFormSubmitTarget.removeAttribute("disabled");
      })
  }

  submit_booking_form_by_click(event) {
      // add new input named submitted to form
      let formElement = event.target.closest('form');
      // add submitted input to form data
      let submittedInput = document.createElement('input');
      submittedInput.type = 'hidden';
      submittedInput.name = 'submitted';
      submittedInput.value = '1';
      formElement.appendChild(submittedInput);
  }

  save_photos_on_admin(event) {
      event.preventDefault();

      if(this.hasPhotoFormTarget){
          this.photoFormTarget.submit();
      }
  }

  expand_full_description() {
    if(this.hasFullDescriptionTarget)
      this.fullDescriptionTarget.classList.toggle("h-56");
      this.fullDescriptionTarget.classList.toggle("h-auto");
  }

  expand_amenities() {
      if(this.hasAmenitiesTarget)
          this.amenitiesTarget.classList.toggle("h-24");
      this.amenitiesTarget.classList.toggle("h-auto");
  }

    set_checkout_invalid_dates(inst) {
        const data = null
        const check_in_format   = (moment(inst.getVal()[0]).format('YYYY/MM/DD'))

        return axios.post(this.calendarGetCheckoutInvalidDatesUrl, {checkin: check_in_format, identifier: this.identifierValue, format: "json"}).then((response)=> {
            inst.setOptions({ invalid: response.data })
        })
    }

  init_map() {
    mapboxgl.accessToken = 'pk.eyJ1IjoicGF4b3NyZXRyZWF0cyIsImEiOiJjbDcwbW9vb3kwZTR4M29zYmJoMmlqcmJoIn0.tKMf62W9VJTdcLspkoMrTg';

    // init map only of lat and lng are present
    if(this.hasMarketLatValue && this.hasMarketLngValue) {
        const property_map = new mapboxgl.Map({
            container: 'property-map', // container ID
            style: 'mapbox://styles/mapbox/streets-v11', // style URL
            center: [this.marketLngValue, this.marketLatValue], // starting position [lng, lat]
            zoom: 14, // starting zoom
            projection: 'globe', // display the map as a 3D globe
            interactive: true
        });

        // create marker with given coordinates and add it to the map
        new mapboxgl.Marker().setLngLat([this.marketLngValue, this.marketLatValue]).addTo(property_map);

        // add navigation to map
        property_map.addControl(new mapboxgl.NavigationControl()).scrollZoom.disable();

        // make map available to other functions
        this.searchMap = property_map
    }
  }

  open_file_dialog(event) {
      event.preventDefault();
      this.addPhotosInputTarget.click();
  }

  init_sortable_images() {
      const sthis = this;
      new Sortable(this.listOfPhotosTarget, {
          animation: 150
      });
  }

  init_fancy_box() {
      Fancybox.bind('[data-fancybox]');
  }

    toggle_satellite(event){
        event.preventDefault();

        if (this.searchMap.getStyle().name === 'Mapbox Streets')
            this.searchMap.setStyle('mapbox://styles/mapbox/satellite-v9');
        else
            this.searchMap.setStyle('mapbox://styles/mapbox/streets-v11');
    }
}
