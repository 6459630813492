import { Controller } from "@hotwired/stimulus"
import { commonsMixins } from "./mixins/commons";

// Connects to data-controller="common"
export default class extends Controller {
  static outlets = ["loader"]

  connect() {
    // init mixins
    commonsMixins(this);

    this.close_popups_on_turbo_response()
    this.remove_alerts_after_2_seconds()
    this.set_rules_for_input_number()
    this.remove_sub_menus_on_click_out()

    document.addEventListener("turbo:render", () => {
        this.set_rules_for_input_number()
    })

    document.addEventListener("click", (event) => {
       this.close_results_on_click_out(event)
    })
  }

  inline_scroll_to(event){
     this.scroll_to(document.querySelector(event.currentTarget.dataset.to));
  }

  scroll_to(element){
    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  close_results_on_click_out(event){
    let results = document.querySelectorAll(".search-results");

    if(results.length > 0) {
        results.forEach((result) => {
           // if click is outside of the search-results or its children then close the search-results
           if (!result.contains(event.target)) {
             result.remove()
           }
        });
    }
  }

  reset() {
      this.element.reset()
  }

  close_popups_on_turbo_response() {
    document.addEventListener("turbo:load", () => {
      const popups = document.querySelectorAll(".popup");
      popups.forEach((popup) => {
        popup.classList.add("hidden");
      });
    });
  }

  remove_alerts_after_2_seconds() {
        document.addEventListener("turbo:load", function(){
            const alerts = document.querySelectorAll(".alert");
            alerts.forEach((alert) => {
                setTimeout(() => {
                    alert.remove();
                }, 2000);
            });
        })
  }

      remove_sub_menus_on_click_out(){
          document.addEventListener("click", (event) => {
              // remove sub-menu with sub-menu and to-remove class on click out if they are visible
              let sub_menus_to_remove = document.querySelectorAll(".sub-menu.to-remove, .sub-menu:not(.to-remove), .sm-sub, .dropdown");

              if(sub_menus_to_remove.length > 0) {
                  event.stopPropagation();

                  const sub_menus_to_remove = document.querySelectorAll(".sub-menu.to-remove");
                  sub_menus_to_remove.forEach((sub_menu) => {
                      if (!sub_menu.contains(event.target)) {
                          sub_menu.remove();
                      }
                  });

                  if (!event.target.classList.contains('search-property-type') && event.target.tagName.toLowerCase() !== 'input') {
                      const sub_menus = document.querySelectorAll(".sub-menu:not(.to-remove, .hidden), .sm-sub:not(.hidden)");
                      sub_menus.forEach((sub_menu) => {
                          if (!sub_menu.contains(event.target)) {
                              sub_menu.classList.add('hidden');
                              let wrapper = sub_menu.closest(".sm-wrapper");
                              let live_button = wrapper.querySelector(".live-button")
                              if (live_button) {
                                  live_button.classList.remove("active");
                              }
                              // if any input inside sub_menu has an value and not 0 then re add class active to live-button
                              let inputs = sub_menu.querySelectorAll("input");
                              if(inputs) {
                                  inputs.forEach((input) => {
                                      if (input.checked || (input.value != "0" && input.value != "")) {
                                          live_button.classList.add("active");
                                      }
                                  })
                              }
                          }
                      });
                  }
              }
          })
      }
}
