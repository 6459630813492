import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="slider"
export default class extends Controller {
  static targets = [ "field" ]

  connect() {
    this.init()
  }

  init(){
    if(this.hasFieldTarget) {
        new Glide(this.fieldTarget).mount()
    }
  }
}
