import { Controller } from "@hotwired/stimulus"
import { post } from '@rails/request.js'
import moment from 'moment'

// Connects to data-controller="third-search"
export default class extends Controller {
  static outlets = ["common"]
  static targets = [
      "form",
      "datePicker",
      "checkin",
      "checkout",
      "propertyType",
      "instant",
      "luxury",
      "cancellationPolicy",
      "loadMore",
      "region",
      "topRightLat",
      "topRightLng",
      "bottomLeftLat",
      "bottomLeftLng",
      "mapMove"
  ]

  connect() {
     this.min = moment();
     this.max = moment().add(15, 'M');

     document.addEventListener("turbo:submit-end", () => {
        this.reset_map()
     })

     document.addEventListener("turbo:frame-render", () => {
        this.on_inside_view_load_more()
        this.on_property_hover_show_map_marker()
     })

     this.init_datepicker()
     this.init_map()
  }

  handleSubmit(event) {
    this.formTarget.requestSubmit()
    this.reset_coordinates()
  }

  init_datepicker(){
    const datepicker = mobiscroll.datepicker(this.datePickerTarget, {
      themeVariant: 'light',
      theme: "material",
      controls: ['calendar'],
      select: 'range',
      display: 'center',
      pages: 2,
      dateFormat: 'DDD DD MMM, YYYY',
      calendarType: 'month',
      returnFormat: 'moment',
      rangeStartLabel: 'Check in',
      rangeEndLabel: 'Check out',
      min: this.min,
      max: this.max,
      onInit: (event, inst) => {
        if (this.hasCheckinTarget && this.checkinTarget.value && this.hasCheckoutTarget && this.checkoutTarget.value) {
          let check_in    = (moment(this.checkinTarget.value).format('YYYY-MM-DD'))
          let check_out   = (moment(this.checkoutTarget.value).format('YYYY-MM-DD'))

          if(check_in === check_out){
            event.inst.setVal(null)
            return false
          }

          inst.setVal([moment(this.checkinTarget.value).toDate(), moment(this.checkoutTarget.value).toDate()])
        }
      },
      onChange: (event, inst) => {
        let check_in    = (moment(event.value[0]).format('YYYY-MM-DD'))
        let check_out   = (moment(event.value[1]).format('YYYY-MM-DD'))

        // if checkin and checkout are the same, set value to null and return false
        if(check_in === check_out) event.inst.setVal(null)

        this.checkinTarget.value    = check_in
        this.checkoutTarget.value   = check_out

        if(check_in && check_out) this.handleSubmit()
      }
    });
  }

  toggle_submenu(event){
      const classState  = event.currentTarget.dataset.classstate
      let submenu       = event.currentTarget.closest(".wrapper").querySelector(".dropdown")

      if(submenu.classList.contains("hidden")){
        submenu.classList.remove("hidden")
        if(classState) event.currentTarget.classList.add("active")
      } else {
        submenu.classList.add("hidden")
        if(!classState) event.currentTarget.classList.remove("active")
      }
  }

  toggle_satellite(event){
      if (this.searchMap.getStyle().name === 'Mapbox Streets')
        this.searchMap.setStyle('mapbox://styles/mapbox/satellite-v9');
      else
        this.searchMap.setStyle('mapbox://styles/mapbox/streets-v11');
  }

  close_submenu(event){
     let element_with_active_class = event.currentTarget.closest(".wrapper").querySelector(".active")

     event.currentTarget.closest(".dropdown").classList.add("hidden")

     if(element_with_active_class) element_with_active_class.classList.remove("active")
  }

  set_instant(event){
    if(event.currentTarget.classList.contains("active"))
        event.currentTarget.classList.remove("active")
    else
        event.currentTarget.classList.add("active")

    if(this.instantTarget.checked)
        this.instantTarget.checked = false
    else
        this.instantTarget.checked = true

    this.handleSubmit()
  }

  set_luxury(event){
    if(event.currentTarget.classList.contains("active"))
        event.currentTarget.classList.remove("active")
    else
        event.currentTarget.classList.add("active")

    if(this.luxuryTarget.checked)
        this.luxuryTarget.checked = false
    else
        this.luxuryTarget.checked = true

    this.handleSubmit()
  }

  set_cancellation_policy(event){
      if(event.currentTarget.classList.contains("active"))
          event.currentTarget.classList.remove("active")
      else
          event.currentTarget.classList.add("active")

      if(this.cancellationPolicyTarget.checked)
          this.cancellationPolicyTarget.checked = false
      else
          this.cancellationPolicyTarget.checked = true

      this.handleSubmit()
  }

  on_inside_view_load_more(event){
    let items = document.querySelectorAll(".load-more-hidden")

    const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        if(!entry.target.classList.contains("inside")) {
                          setTimeout(function (){
                              entry.target.classList.add('inside');
                              entry.target.classList.remove('invisible');
                          }, 200);
                        }
                    }
                });
    }, {
                root: null, // Use the viewport as the root
                rootMargin: '0px',
                threshold: 0 // Trigger when any part of the element is visible
    });

    window.addEventListener('scroll', () => {
        if(items){
          items.forEach(item => {
            observer.observe(item)
          })
        }
    })
  }

  debounce(func, wait) {
      let timeout;
      return function(...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), wait);
      };
    }

  init_map(){
        // Map api key
        mapboxgl.accessToken = 'pk.eyJ1IjoicGF4b3NyZXRyZWF0cyIsImEiOiJjbDcwbW9vb3kwZTR4M29zYmJoMmlqcmJoIn0.tKMf62W9VJTdcLspkoMrTg';

        this.map_properties().then(([properties, coordinates, zoom]) => {
          // init map and add coordinates and zoom level
          this.searchMap = new mapboxgl.Map({
            container: 'search-map', // container ID
            style: 'mapbox://styles/mapbox/streets-v11', // style URL
            center: coordinates, // starting position [lng, lat]
            zoom: zoom, // starting zoom
            projection: 'globe', // display the map as a 3D globe
          });

          // set properties markers
          this.set_markers(properties)

          // set map controls
          this.searchMap.addControl(new mapboxgl.NavigationControl()).scrollZoom.disable();

          this.debouncedCoordinates = this.debounce(this.set_coordinates.bind(this), 300);

          this.searchMap.on("dragend", this.debouncedCoordinates)
          this.searchMap.on("zoom", this.debouncedCoordinates)
        })
      }

  set_coordinates(){
    let bounds = this.searchMap.getBounds()

    this.topRightLatTarget.value    = bounds._ne.lat
    this.topRightLngTarget.value    = bounds._ne.lng

    this.bottomLeftLatTarget.value  = bounds._sw.lat
    this.bottomLeftLngTarget.value  = bounds._sw.lng
    this.mapMoveTarget.value        = "true"

    this.formTarget.requestSubmit()
  }

  reset_coordinates(){
    this.topRightLatTarget.value    = ""
    this.topRightLngTarget.value    = ""
    this.bottomLeftLatTarget.value  = ""
    this.bottomLeftLngTarget.value  = ""
    this.mapMoveTarget.value        = "false"
  }

  async map_properties(){
        let src        = new URL(document.querySelector("#properties").getAttribute("src"));

        const response = await post("/properties-for-map?" + src.searchParams.toString(), {responseKind: "json"})
        if (response.ok) {
            const data = await response.json;
            return [data.properties, data.coordinates, data.zoom];
        }
      }

  reset_map(){
        // The the url changes cause of a filter we get again the properties which are based on the map region
        this.map_properties().then(([properties, coordinates, zoom]) => {
          // set properties markers
          this.set_markers(properties)

          this.on_property_hover_show_map_marker()

          // move map to new coordinates and zoom
          if(this.mapMoveTarget.value === "false")
            this.searchMap.flyTo({center: coordinates, zoom: zoom})
        })
  }

  set_markers(properties){
        let markers_array = []

        // remove all markers
        if(this.searchMapMarkers) {
          this.searchMapMarkers.forEach((marker) => {
            marker.remove()
          })
        }

        // return of properties is empty
        if(!properties) return

        properties.forEach((property) => {
          const latitude  = property["latitude"];
          const longitude = property["longitude"];

          // next if latitude or longitude are not present
          if(!latitude || !longitude) return

          const slug      = property["slug"];
          let html_popup  = new mapboxgl.Popup({ maxWidth: 300 }).setHTML(property["html"]);

          if(latitude && longitude) {
            let marker      = new mapboxgl.Marker().setLngLat([latitude, longitude]);

            // add marker variable inside div of markerDiv
            marker.slug     = slug

            if(html_popup)
              marker.setPopup(html_popup)

            marker.addTo(this.searchMap)

            markers_array.push(marker)
          }
        });

        this.searchMapMarkers = markers_array
  }

  scroll_to_properties_top(){
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  clear_search(event){
    event.preventDefault()
    this.formTarget.reset()
    this.handleSubmit()
  }


  on_property_hover_show_map_marker(event){
//    let properties      = document.querySelectorAll(".search-property")
//
//    if(properties){
//      properties.forEach(property => {
//            let property_slug   = property.dataset.slug
//            let marker          = this.searchMapMarkers.find(marker => marker.slug === property_slug)
//
//            property.addEventListener("mouseover", () => {
//                marker.getElement().querySelector("path").setAttribute("fill", "#000")
//            })
//
//            property.addEventListener("mouseleave", () => {
//                marker.getElement().querySelector("path").setAttribute("fill", "#3FB1CE")
//            })
//        })
//    }
  }
}
