import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sign-up"
export default class extends Controller {
  static outlets = [
    "sign-in",
    "forgot-password"
  ]

  static targets = [
    "wrapper",
    "inner"
  ]

  connect() {}

  open(event) {
    this.wrapperTarget.classList.remove("hidden")
    this.innerTarget.classList.add("animate__fadeInDown")
  }

  close(event) {
    this.innerTarget.classList.remove("animate__fadeInDown")
    this.wrapperTarget.classList.add("hidden")
  }

  open_sign_in(){
    this.close()
    this.signInOutlet.open()
  }

  open_forgot_password(){
    this.close()
    this.forgotPasswordOutlet.open()
  }
}
