import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="terms"
export default class extends Controller {
  static targets = [ "header", "content" ]

  connect() {
  }

  changeTab(event) {
    this.headerTargets.forEach((header) => {
        header.classList.remove('active')
    })

    event.currentTarget.classList.add('active')

    this.contentTargets.forEach((content) => {
        content.classList.add('hidden')
    })

    this.contentTargets.find((content) => {
        if (content.dataset.slug == event.currentTarget.dataset.slug) {
            content.classList.remove('hidden')
        }
    })
  }
}
