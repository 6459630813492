import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="calendar-rate-filters"
export default class extends Controller {
  static targets = ["header", "item"]

  connect() {
    this.init()
  }

  init() {
    this.headerTargets.forEach((header) => {
      header.addEventListener('click', (event) => {
       this.set_active_header(event.currentTarget)
       let type = event.currentTarget.getAttribute("type")
        if(type === "all"){
            this.itemTargets.forEach((item) => {
                item.classList.remove("hidden")
            })
        } else if(type === "month"){
            // if month is selected, hide all items that are not between the current day and the end of month
            this.itemTargets.forEach((item) => {
                let date_format = moment(item.querySelector(".d").textContent.replace(/\s+/g, '').split("-")[0], "DD/MM/YYYY")
                if(date_format.isBefore(moment(), 'day') || date_format.isAfter(moment().endOf('month'), 'day')){
                    item.classList.add("hidden")
                } else {
                    item.classList.remove("hidden")
                }
            })
        } else if(type === "year"){
            // if year is selected, hide all items that are not between the current day and the end of year
            this.itemTargets.forEach((item) => {
                let date = moment(item.querySelector(".d").textContent.replace(/\s+/g, '').split("-")[0], "DD/MM/YYYY")
                if(date.isBefore(moment(), 'day') || date.isAfter(moment().endOf('year'), 'day')){
                    item.classList.add("hidden")
                } else {
                    item.classList.remove("hidden")
                }
            })
        } else if(type === "expired") {
            // if expired is selected, hide all items that are after current day
            this.itemTargets.forEach((item) => {
                let date = moment(item.querySelector(".d").textContent.replace(/\s+/g, '').split("-")[0], "DD/MM/YYYY")
                if(date.isAfter(moment(), 'day')){
                    item.classList.add("hidden")
                } else {
                    item.classList.remove("hidden")
                }
            })
        }
      })
    })
  }

  set_active_header(header) {
      this.headerTargets.forEach((header) => {
          header.classList.remove("active")
      })

      header.classList.add("active")
  }
}
