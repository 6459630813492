import { Controller } from "@hotwired/stimulus"
import { post } from '@rails/request.js'

// Connects to data-controller="blog"
export default class extends Controller {
  static targets = [ "category" ]

  connect() {
  }

  filter_by_category(event) {
        // remove active class from headers
        this.categoryTargets.forEach(category => {
          category.classList.remove('bg-black', 'text-white')
          category.classList.add('bg-white', 'text-black')
        })

        // add active class to clicked header
        event.currentTarget.classList.remove('bg-white', 'text-black')
        event.currentTarget.classList.add('bg-black', 'text-white')

     post('/blog/search_by_category', {
         body: {
            query: event.currentTarget.dataset.category
         },
         responseKind: 'turbo-stream'
     })
  }
}
