import { Controller } from "@hotwired/stimulus"
import { datepickerMixins } from "./mixins/datepicker";
import { post } from '@rails/request.js'
import moment from "moment";

// Connects to data-controller="property-datepicker"
export default class extends Controller {
  static outlets = ["property-inline-datepicker", "property"]

  static values = {
    identifier: String,
    checkin: String,
    checkout: String,
    availabilityWindow: Number,
  }

  static targets = [
      "datepicker",
      "form",
      "checkin",
      "checkout"
  ]

  connect() {
    this.min_datepicker_range                   = moment();
    this.max_datepicker_range                   = moment().add(15, 'M');

    this.setBookingDetailsUrl                   = document.querySelector("meta[name='set-booking-details']").getAttribute("content")
    this.datepickerInvalidDatesUrl              = document.querySelector("meta[name='get-invalid-dates']").getAttribute("content")
    this.datepickerInvalidDateColorsUrl         = document.querySelector("meta[name='get-invalid-dates-colors']").getAttribute("content")
    this.datepickerAdvancedNoticeUrl            = document.querySelector("meta[name='get-property-advanced-notice-days']").getAttribute("content")
    this.datepickerPropertyPriceLabelsRatesUrl  = document.querySelector("meta[name='get-property-price-labels']").getAttribute("content")
    this.calendarGetCheckoutInvalidDatesUrl     = document.querySelector("meta[name='get-property-invalid-dates-for-checkout']").getAttribute("content")
    this.checkinField                           = document.querySelector("#property_checkin")
    this.checkoutField                          = document.querySelector("#property_checkout")
    this.form                                   = document.querySelector("#property-booking-form")

    // Init mixins
    datepickerMixins(this);

    // initializations
    this.init();
    this.fromInlineDatepicker = false
  }

  init() {
    if (!this.hasDatepickerTarget) return

    const data = Promise.all([
//        this.get_price_labels(),
        this.get_invalid_dates(),
        this.get_invalid_date_colors(),
//        this.get_checkout_invalid_dates(),
//        this.get_advanced_notice_dates(),
    ])

    data.then((response) => {
        this.datepicker = mobiscroll.datepicker(this.datepickerTarget, {
          theme: 'material',
          themeVariant: 'light',
          select: 'range',
          display: 'center',
          dateFormat: 'DDD DD MMM, YYYY',
          calendarType: 'month',
          returnFormat: 'moment',
          pages: 2,
          min: this.min_datepicker_range,
          showRangeLabels: true,
          inRangeInvalid: false,
          rangeEndInvalid: true,
          rangeHighlight: true,
          rangeStartLabel: 'Check-in',
          rangeEndLabel: 'Check-out',
          invalid: this.invalid_dates,
//          labels: this.price_labels,
          colors: this.invalid_dates_colors,
          onInit: (event, inst) => {
            if(this.hasCheckinValue && this.hasCheckoutValue && (this.checkinValue != this.checkoutValue)){
                inst.setVal([this.checkinValue, this.checkoutValue])
                this.get_booking_details(true)
            }
          },
          onTempChange: (event, inst) => {
            const checkin   = moment(event.value[0])
            const checkout  = moment(event.value[1])

            if(checkin && checkout && (checkout.isAfter(checkin))){
              this.checkinTarget.value   = checkin.format('YYYY-MM-DD')
              this.checkoutTarget.value  = checkout.format('YYYY-MM-DD')

              this.propertyInlineDatepickerOutlet.inline_datepicker.setVal([checkin.format('YYYY-MM-DD'), checkout.format('YYYY-MM-DD')])

              this.get_booking_details()
            }
          }
//          renderDay: function (args) {
//            let date                = moment(args.date).format('YYYY-MM-DD')
//            let air_cell_div        = `<div class="air-cell date_${date}">` + args.date.getDate() + `</div>`
//            let tip_cloud_div       = "<div class='tip-cloud'></div>"
//            let complete_html       = ""
//
//            complete_html = air_cell_div + tip_cloud_div
//
//            return complete_html          onCellHoverIn: function (event, inst) {
//                                              let parent      = event.target.closest(".mbsc-calendar-cell")
//                                              let date        = parent.querySelector(".air-cell").classList[1].split("_")[1]
//                                              let tip_cloud   = parent.querySelector(".tip-cloud")
//
//                                              if (parent) {
//                                                  let min_stay_day = Array.from(parent.classList).find((c) => c.startsWith("min-stay-day"));
//
//                                                  if (parent.classList.contains("mbsc-range-day-start")){
//                                                      // if parent has class mbsc-range-day-start we add "checkin day" to the tip cloud div
//                                                      tip_cloud.textContent = "checkin day"
//                                                  } else if(parent.classList.contains("mbsc-range-day-end")){
//                                                      // if parent has class mbsc-range-day-start we add "checkout day" to the tip cloud div
//                                                      tip_cloud.textContent = "checkout day"
//                                                  } else if (min_stay_day) {
//                                                      // if parent element has class min-stay-day we add the minimum stay days to the tip cloud div
//                                                      let min_stay_day_number = min_stay_day.split("-")[3]
//                                                      tip_cloud.textContent = min_stay_day_number + " minimum nights"
//                                                  } else if (parent.classList.contains("checkout-only")) {
//                                                      // if parent element has class checkout-only we add the text "checkout only" to the tip cloud div
//                                                      tip_cloud.textContent = "checkout only"
//                                                  } else {
//                                                      // return empty tip cloud div
//                                                      tip_cloud.textContent = ""
//                                                  }
//                                              }
//
//                                              let checkin_selected = document.querySelector(".mbsc-range-day-start.mbsc-range-day-end")
//
//                                              if (checkin_selected) {
//                                                  // from checkin_selected find the class starting with "date-" and convert it to a date
//                                                  let checkin_date_class = checkin_selected.querySelector(".air-cell").classList[1]
//                                                  let checkin_date       = checkin_date_class.split("_")[1]
//                                                  let minRange           = parseInt(this.date_minimum_stays || 7)
//
//                                                  // find the last minimum stay date
//                                                  let last_minimum_stay_date = moment(checkin_date).add(minRange, 'days').format('YYYY-MM-DD')
//
//                                                  // between checkin and last_minimum_stay_date find tip cloud div and add the text "7 minimum nights"
//                                                  if (moment(date).isBetween(checkin_date, last_minimum_stay_date)) {
//                                                      tip_cloud.textContent = minRange + " minimum nights"
//                                                  }
//                                              }
//
//                                              // if tip cloud text is not empty we display it
//                                              if (tip_cloud && tip_cloud.textContent != "") tip_cloud.classList.add("active")
//                                            },
//                                            onCellHoverOut: function (event, inst) {
//                                                  // remove active class from tip cloud
//                                                  let tip_cloud = event.target.querySelector(".tip-cloud")
//                                                  if (tip_cloud) tip_cloud.classList.remove("active")
//                                            },
//          },


        });
    })
  }

  get_booking_details(first_load = false) {
    let data = new FormData(this.formTarget)

    if (first_load) data.append("first_load", "1")

    post(this.setBookingDetailsUrl, {
        body: data,
        responseKind: 'turbo-stream'
    })
  }
}
