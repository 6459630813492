import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="delete"
export default class extends Controller {
  static values = {
    message: String,
    url: String,
    id: String,
  }

  connect() {
    axios.defaults.headers.common['Accept']       = "text/vnd.turbo-stream.html"
    axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    this.getDeleteModalUrl                        = document.querySelector("meta[name='get-delete-modal']").getAttribute("content")
  }

  open() {
    axios.post(this.getDeleteModalUrl, {url: this.urlValue, message: this.messageValue, id: this.idValue}).then((response)=> {
      Turbo.renderStreamMessage(response.data)
    })
  }
}
