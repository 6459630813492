import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="faq-item"
export default class extends Controller {
  static targets = [ "question", "answer" ]
  static values  = { id: Number }
  static outlets = [ "common" ]

  connect() {
    this.open_if_in_url()
  }

  open() {
    this.answerTarget.classList.remove('hidden')
  }

  close() {
    this.answerTarget.classList.add('hidden')
  }

  open_if_in_url() {
    const url = new URL(window.location.href)
    const item_id = url.searchParams.get('item')
    if (item_id == this.idValue) {
      this.open()
      this.commonOutlet.scroll_to(this.questionTarget)
    }
  }
}
