import { Controller } from "@hotwired/stimulus"
import { commonsMixins } from "./mixins/commons";

// Connects to data-controller="ical"
export default class extends Controller {
  static targets = [
      "icalTab"
  ]

  connect() {
    // Init mixins
    commonsMixins(this);

  }

  scroll_to_ical_tab() {
    this.scroll_to_element(this.icalTabTarget)
    this.toggle_ical_tab()
  }

  toggle_ical_tab(event) {
    this.icalTabTarget.click()
  }
}
