import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="verification"
export default class extends Controller {
  static outlets = ["sign-in"]
  connect() {
  }

  open_sign_in(event) {
    this.signInOutlet.open()
  }
}
