export const datepickerMixins = controller => {
    Object.assign(controller, {
        async get_invalid_dates() {
            this.invalid_dates = await axios.post(this.datepickerInvalidDatesUrl, {identifier: this.identifierValue, format: "json"}).then((response)=> {
                 return response.data
            })
        },
        async get_invalid_date_colors() {
            this.invalid_dates_colors = await axios.post(this.datepickerInvalidDateColorsUrl, {identifier: this.identifierValue, format: "json"}).then((response)=> {
                return response.data
            })
        },
        async get_rates() {
           this.rates = await axios.post(this.datepickerPropertyRatesUrl, {identifier: this.identifierValue, view: "property", format: "json"}).then((response)=> {
                return response.data
            })
        },
        async get_price_labels() {
            this.price_labels = await axios.post(this.datepickerPropertyPriceLabelsRatesUrl, {identifier: this.identifierValue, view: "property", format: "json"}).then((response)=> {
                return response.data
            })
        },

        async get_checkout_invalid_dates() {
            const response = await axios.post(this.calendarGetCheckoutInvalidDatesUrl, {identifier: this.identifierValue, format: "json"}).then((response)=> {
                 return response.data
            })

            this.date_minimum_stays     = response.date_minimum_stays
            this.maximum_stay           = response.maximum_stay
            this.checkout_invalid_dates = response.invalid_dates
        },

        async get_checkout_invalid_date_colors() {
            const response = await axios.post(this.datepickerInvalidDatesForCheckoutColors, {identifier: this.identifierValue, format: "json"}).then((response)=> {
                 return response.data
            })

            this.checkout_invalid_colors = response
        },

        async get_advanced_notice_dates() {
            this.advanced_notice_dates = await axios.post(this.datepickerAdvancedNoticeUrl, {identifier: this.identifierValue, format: "json"}).then((response)=> {
                return response.data
            })
        },

          set_minimum_stay_class_styles() {
            setTimeout(() => {
                let minimum_stays_divs = document.querySelectorAll(".minimum-stay-disabled:not(.checkout-only)")

                minimum_stays_divs.forEach((div) => {
                    let text_div              = div.querySelector(".mbsc-calendar-cell-text")
                    text_div.style            = "color:#717171;opacity:1;text-decoration:none!important;cursor:default;"
                })

                this.create_tip_cloud_above_checkout_minimum_stay_days()
            }, 500)
          },

           set_checkout_only_class_styles() {
              setTimeout(() => {
                  let checkout_only_divs = document.querySelectorAll(".checkout-only")

                  checkout_only_divs.forEach((div) => {
                      let text_div              = div.querySelector(".mbsc-calendar-cell-text")
                      text_div.style            = "color:#717171;opacity:1;text-decoration:none!important;cursor:pointer;"
                  })

                  this.create_tip_cloud_above_checkout_only()
              }, 500)
           },

             create_tip_cloud_above_checkout_minimum_stay_days() {
               let minimum_stay_days = document.querySelectorAll(".minimum-stay-disabled:not(.checkout-only)")

               minimum_stay_days.forEach((div) => {
                   // find class from div which starts with "min-stay-day"
                   let min_stay_day = div.className.split(' ').find(c => c.startsWith('min-stay-day'))
                   // get day number from class
                   let day = min_stay_day.split('-')[3]

                   // create a new div
                   let tip_cloud       = document.createElement("div")
                   tip_cloud.className = "tip-cloud"
                   tip_cloud.innerHTML = day + " nights minimum"

                   div.appendChild(tip_cloud)
               })
             },

               create_tip_cloud_above_checkout_only() {
                 let checkout_only_days = document.querySelectorAll(".checkout-only")

                 checkout_only_days.forEach((div) => {
                     // create a new div
                     let tip_cloud       = document.createElement("div")
                     tip_cloud.className = "tip-cloud"
                     tip_cloud.innerHTML = "checkout only"

                     div.appendChild(tip_cloud)
                 })
               },

                datepicker_cell_click(){
                   let cells = document.querySelectorAll(".checkout-only, .minimum-stay-disabled .mbsc-calendar-cell-text");

                   if(!cells) return

                   cells.forEach((cell) => {
                       cell.style.cursor = "pointer"; // Add cursor: pointer to cell style

                       cell.addEventListener("click", (event) => {

                           // Remove active class from other cells
                           document.querySelectorAll(".mbsc-calendar-cell-text.active").forEach((activeCell) => {
                               activeCell.classList.remove("active");
                           });

                             document.querySelectorAll(".tip-cloud").forEach((item) => {
                               item.style.visibility = 'hidden';
                             });

                           const currentTipCloud = event.target.closest(".mbsc-calendar-cell").querySelector(".tip-cloud");
                           if(currentTipCloud) currentTipCloud.style.visibility = 'visible';

                           // Add active class to the current cell
                           event.target.classList.add("active");
                       })

                       cell.addEventListener("mouseout", (event) => {
                        const currentTipCloud = event.target.closest(".mbsc-calendar-cell").querySelector(".tip-cloud");
                        if(currentTipCloud) currentTipCloud.style.visibility = 'hidden';
                        event.target.classList.remove("active");
                       })
                   });
                 }
    });
};