export const commonsMixins = controller => {
    Object.assign(controller, {
        scroll_to_element(element, offset) {
            element.scrollIntoView({top: offset, behavior: "smooth", block: "center", inline: "nearest"});
        },
        set_rules_for_input_number() {
            let number_inputs = document.querySelectorAll("input[type=number]")
            if(number_inputs.length > 0) {
                number_inputs.forEach(function (input) {
                    input.addEventListener("mousewheel", function (event) {
                        this.blur()
                    });
                })
            }
        }
    });
};