import { Controller } from "@hotwired/stimulus"
import { post } from '@rails/request.js'

// Connects to data-controller="faq"
export default class extends Controller {
  static targets = [ "form", "header" ]
  static outlets = [ "common" ]

  connect() {
  }

  submitForm(event) {
    this.formTarget.requestSubmit()
  }

  filter_by_category(event) {
    // remove active class from headers
    this.headerTargets.forEach(header => {
      header.classList.remove('bg-black', 'text-white')
      header.classList.add('bg-white', 'text-black')
    })

    // add active class to clicked header
    event.currentTarget.classList.remove('bg-white', 'text-black')
    event.currentTarget.classList.add('bg-black', 'text-white')


     post('/faq/search_by_category', {
         body: {
            title: event.currentTarget.dataset.category
         },
         responseKind: 'turbo-stream'
     })
  }

  show_item(event) {
    this.headerTargets.forEach(header => {
        header.classList.remove('bg-black', 'text-white')
        header.classList.add('bg-white', 'text-black')
    })

    // add active class to clicked header
    event.currentTarget.classList.remove('bg-white', 'text-black')
    event.currentTarget.classList.add('bg-black', 'text-white')

    event.currentTarget.closest('.search-results').remove()
  }

  toggle_question(event) {
    event.preventDefault()

    // hide other answers
    let answers = document.querySelectorAll('.answer')

    answers.forEach(answer => {
      if (!answer.classList.contains('hidden')) {
        answer.classList.add('hidden')
      }
    })

    event.currentTarget.closest('.item').querySelector('.answer').classList.toggle('hidden')
  }
}
