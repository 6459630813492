import { Controller } from "@hotwired/stimulus"
import { debounce } from "lodash";
import { post } from "@rails/request.js"
import moment from 'moment'

export default class extends Controller {
  static outlets = ["third-search"]

  static targets = [
      "region",
      "regionPlaceholder",
      "regionDropdown",
      "typePlaceholder",
      "type",
      "typeDropdown",
      "form",
      "checkin",
      "checkout",
      "guests",
      "luxury"
  ]

  connect() {
    this.init_datepicker()
  }

  get_regions(event) {
    // Clear any previous timer
    clearTimeout(this.debounceTimer);

    const dom_id = event.currentTarget.closest("div").getAttribute("id");

    // if event is clicked then add words to the search
    let words = ""
    if(event.type == "click") words = event.currentTarget.textContent

    // Set a new timer for debouncing
    this.debounceTimer = setTimeout(() => {
      if (this.hasRegionDropdownTarget) {
        this.regionDropdownTargets.forEach((element) => element.remove());
      }

      post("/search/destination-list", {
        body: {
            dom_id: dom_id,
            words: words
        },
        responseKind: "turbo-stream"
      })

    }, 500); // Adjust the delay as needed
  }

  get_types(event) {
    post("/search/type-list", {
        body: {
            words: event.currentTarget.value
        },
        responseKind: "turbo-stream"
    })
  }

  set_region(event) {
    this.regionPlaceholderTarget.value  = event.currentTarget.dataset.placeholder
    this.regionTarget.value             = event.currentTarget.dataset.slug

    if(this.hasThirdSearchOutlet) this.thirdSearchOutlet.handleSubmit()

    // remove dropdown
    this.regionDropdownTarget.remove()
  }

  set_type(event) {
    this.typePlaceholderTarget.value    = event.currentTarget.dataset.placeholder
    this.typeTarget.value               = event.currentTarget.dataset.slug

    this.typeDropdownTarget.remove()
  }

  handleFirstSearchSubmit(event){
    event.preventDefault()
    let url_params = []

    url_params.push(this.regionTarget.value)
    url_params.push(this.typeTarget.value)

    url_params = url_params.filter(n => n)
    url_params = url_params.filter((item) => item !== "all")

    if(url_params.length == 0)
      url_params.push("properties")
    else
      url_params = [url_params.join("/")]


    window.location.href = url_params.join("/");
  }

  init_datepicker(){
    const min= moment();
    const max= moment().add(15, 'M');

    const datepicker = mobiscroll.datepicker(".mobiscroll", {
      themeVariant: 'light',
      theme: "material",
      controls: ['calendar'],
      select: 'range',
      display: 'anchored',
      // inRangeInvalid: false,
      // rangeEndInvalid: true,
      dateFormat: 'DDD DD MMM, YYYY',
      rangeStartLabel: 'Check in',
      rangeEndLabel: 'Check out',
      min: min,
      max: max,
      responsive: {
        xsmall: {
          display: 'center',
          pages: 2,
          touchUi: true
        },
        medium: {
          display: 'center',
          pages: 2,
          touchUi: true
        },
        large: {
          display: 'anchored',
          pages: 2
        },
      },
      onClose: function (event, inst) {
        let check_in    = (moment(event.value[0]).format('YYYY-MM-DD'))
        let check_out   = (moment(event.value[1]).format('YYYY-MM-DD'))

        if(check_in === check_out){
          event.inst.setVal(null)
          return false
        }

        const checkin_field             = document.querySelector("#checkin")
        const checkout_field            = document.querySelector("#checkout")

        if(checkin_field)
          checkin_field.value           = check_in

        if(checkout_field)
          checkout_field.value          = check_out
      }
    });
  }
}
