import { Controller } from "@hotwired/stimulus"
import { commonsMixins } from "./mixins/commons";

// Connects to data-controller="calendar-datepicker"
export default class extends Controller {
  static outlets = ["calendar"]
  static targets = [
      "displayRange",
      "datepicker",
      "checkin",
      "checkout",
  ]

  connect() {
    this.min_datepicker_range = moment();
    this.max_datepicker_range = moment().add(12, 'M');

    // init mixins
    commonsMixins(this);

    this.init()
  }

  init() {
    this.datepicker = mobiscroll.datepicker(this.datepickerTarget, {
      themeVariant: 'light',
      theme: "material",
      controls: ['calendar'],
      select: 'range',
      display: 'center',
      dateFormat: 'DDD DD MMM, YYYY',
      calendarType: 'month',
      pages: 1,
      min: this.min_datepicker_range,
      max: this.max_datepicker_range,
      onChange: (event, inst) => {
        const check_in  = moment(event.value[0]).format('MMM DD, YYYY');
        const check_out = moment(event.value[1]).format('MMM DD, YYYY');

        this.calendarOutlet.calendar.setVal([event.value[0], event.value[1]])
        this.displayRangeTarget.textContent = `${check_in} - ${check_out}`

        this.checkinTarget.value  = check_in;
        this.checkoutTarget.value = check_out;
      }
    });
  }
}
